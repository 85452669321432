@use '../Abstracts' as lib;

// ========================
// ===== Download Box =====
// ========================

.frame-type-uploads {
  width: auto;
  max-width: unset;
}

.downloads {
  padding-inline-start: 0;

  @include lib.set-content-width(lib.$min-content-width);

  &__item {
    display: flex;
    gap: lib.$size-24;
    justify-content: space-between;
    align-items: flex-end;

    &:not(:last-of-type) {
      border-bottom: 1px solid lib.$col-black-20;
    }
  }

  &__link {
    display: flex;
    flex-grow: 1;
    padding-block: lib.$size-16;
    text-decoration: transparent;

    &--filename {
      @include lib.word-wrap;
    }
  }

  &__icon {
    min-width: lib.$size-24;
    margin-inline-end: lib.$size-8;
    margin-block-start: .1em;
    fill: lib.$col-text;
  }

  &__filesize {
    color: lib.$col-text-shaded;
    white-space: nowrap;
    margin-block-end: 1.2em;
  }
}
