@use '../Abstracts' as lib;

// =============================
// ===== Tables Stylesheet =====
// =============================

// This setup allows you to copy a raw table from Excel and paste it into the CK-Editor
// and overwrite the usual unwanted styling without the need to change the style of
// the table in the editor to default.

table {
  display: table;
  table-layout: fixed;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0 0 1em;

  th, td {
    padding: 1rem;
    border: 1px solid lib.$col-black-20;
    word-wrap: normal;
    hyphens: auto;
  }

  th {
    background: lib.$col-background fixed;
    color: lib.$col-text;
  }

  tr {
    color: lib.$col-black-80;
  }

  tr:nth-child(even) {
    background-color: lib.$col-white;
  }

  tr:nth-child(odd) {
    background-color: lib.$col-black-05;
  }
}

// Static HTML-ELement with table filled by JS from page ID 2953
.h-responsiveTable {
  overflow-x: auto;
  font-size: lib.$font-small-size-fluid;

  @include lib.set-content-width(lib.$max-content-width);

  .o-table {
    table-layout: auto;
  }
}
