@use '../Abstracts' as lib;

// =====================
// ===== Main Menu =====
// =====================

.main-menu__wrap {
  position: fixed;
  top: lib.$toolbar-height;
  left: 0;
  z-index: lib.$z-index-menu;
  overflow: auto;
  width: 100%;
  height: calc(100% - #{lib.$toolbar-height});
  padding: lib.$size-16 0 lib.$size-48;
  background-color: lib.$col-black-05;
  transition: transform lib.$time lib.$timing-function;
  transform: translateY(-100%);

  &--open {
    transform: translateY(0);
  }
}

.main-menu {
  padding: lib.$size-16 lib.$side-padding 0;

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: block;

    &:not(:last-of-type) {
      border-bottom: 1px solid lib.$col-black-20;
    }
  }

  &__link {
    display: block;
    padding: lib.$size-16 0;
    color: lib.$col-text;
    font-weight: bold;
    line-height: 1.2em;
    text-decoration: none;

    &--active {
      color: lib.$col-blue;
    }
  }

  @include lib.breakpoint-min(lg) {
    padding: 0;

    &__list {
      display: flex;
      justify-content: center;
    }

    &__item {
      &:not(:last-of-type) {
        margin-right: lib.$size-8;
        border-bottom: none;
      }
    }

    &__link {
      padding: lib.$size-8 * .5;
      font-size: lib.$font-small-size-fluid;
    }
  }
}
