// ==========================
// ===== drk_memberform =====
// ==========================

@use '../Abstracts' as lib;
@import '../Atoms/utility-classes';

#memberform {
  .donation-form__amount-button {
    margin-block-end: 1em;
    margin-block-start: 0.75rem;
  }

  .donation-form__donator,
  .donation-form__payment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .donation-form__donator-button,
  .donation-form__payment-button {
    margin: 0 1em 1em 0;
    margin-block-end: 1em;
    margin-block-start: 0.75rem;
  }

  .donation-form__frequency {
    background-color: #fff;
  }

  .button--donate {
    width: unset;
  }

}
.frame-list-type-drkmemberform_memberform,
.frame-type-drkmemberform_donationform {

  @include bg;
  @include bg--lightgrey;

  width: unset;
  max-width: unset;

  padding-top: lib.$size-32;
  padding-bottom: lib.$size-32;


}

.tx-drk-memeberform-verification {
  @include lib.set-content-width(lib.$med-content-width);
}
