@use 'sass:color';
@use '../Abstracts' as lib;

// =======================
// ===== Quick Links =====
// =======================

.quicklinks {
  padding-inline: lib.$size-24;
  font-size: lib.$font-small-size-fluid;

  &__title {
    color: lib.$col-black-60;
    text-transform: uppercase;
    margin-block-end: lib.$size-16;
  }

  &__link {
    display: block;
    padding: lib.$size-8 0;
    color: lib.$col-dark-blue;
    line-height: 1.2em;
  }
}
