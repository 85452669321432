@use '../Abstracts' as lib;

// ======================================
// ===== DONATION BAROMETER ELEMENT =====
// ======================================

.frame-list-type-drkdonations_barometer {
  width: unset;
  max-width: unset;
}

.donation-barometer {
  @include lib.set-content-width(lib.$min-content-width);

  &__headline {
    text-align: center;
    margin-block-end: lib.$size-16;
  }

  &__intro {
    text-align: center;
    margin-block-end: lib.$size-32;
  }

  &__progress {
    position: relative;
    height: 16px;
    border-radius: lib.rem(8px);
    background-color: lib.$col-black-20;
  }

  &__amount {
    display: flex;
    justify-content: space-between;
    margin-block-start: lib.$size-8;
  }

  &__maxamount {
    color: lib.$col-black-40;

    &--full {
      color: lib.$col-text;
    }
  }

  &__bar {
    max-width: 100%;
    height: 16px;
    border-radius: lib.rem(8px);
    background-color: lib.$col-black-60;
  }

  &__goal {
    position: absolute;
    top: 0;
    width: 2px;
    height: 16px;
    background-color: lib.$col-black-60;

    &:hover {
      cursor: pointer;

      .donation-barometer__popup {
        opacity: 1;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 6px;
      height: 16px;
    }

    &:before {
      left: -6px;
    }

    &:after {
      right: -6px;
    }
  }

  &__popup {
    position: absolute;
    top: 0;
    width: 100%;
    min-width: 115px;
    padding: lib.$size-8;
    border-radius: lib.rem(6px);
    box-shadow: 0 lib.rem(7px) lib.$size-32 rgba(0 0 0 / 20%);
    opacity: 0;
    pointer-events: none;
    transition: opacity lib.$time ease;
    transform: translateY(-100%);

    &--alignright {
      right: unset;
      left: 0;
    }

    &--alignleft {
      right: 0;
      left: unset;
    }
  }

  &__number,
  &__text {
    display: block;
    pointer-events: none;
  }
}
