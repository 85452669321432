@use 'sass:math';
@use 'sass:string';
@use '../Abstracts' as lib;

/* open-sans-300 - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-300italic - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 300;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-regular - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-italic - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 400;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-500 - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 500;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-500italic - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 500;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-600 - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-600italic - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 600;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700 - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700italic - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 700;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-800 - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 800;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-800italic - cyrillic_greek_latin_math */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 800;
  src: url('../Fonts/OpenSans/open-sans-v40-cyrillic_greek_latin_math-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
