// =========================
// ===== Include Fonts =====
// =========================

// fonts are now included in the drk_template_nonfree extension, these also ship the needed files

// =================
// ===== Fonts =====
// =================

// We use font family as families containing all single type faces
// To use bold or italic variant please use semantic HTML tags like <strong> or <em> instead.
// If you cannot use HTML <tags> use [font-weight: bold] or [font-style: italic]
$main: 'Helvetica Neue', 'OpenSans', 'Arial', sans-serif;


// ======================
// ===== Font Sizes =====
// ======================

// main font size
// minimum for forms should be 16px to prevent zooming on mobile

:root {
  // Based on https://royalfig.github.io/fluid-typography-calculator/
  // Min Viewport: 57.9rem (390px)
  // Max Viewport: 120rem (704px)

  --main-line-height: 1.5;
  --main-min-size: 1.13rem;
  --main-max-size: 1.25rem;
  --main-size-fluid: clamp(var(--main-min-size), calc(.98rem + .61vw), var(--main-max-size));

  // lead paragraph font size
  --lead-min-size: 1.35rem;
  --lead-max-size: 1.56rem;
  --lead-size-fluid: clamp(var(--lead-min-size), calc(1.09rem + 1.07vw), var(--lead-max-size));

  // small/fineprint font size
  --small-min-size: .94rem;
  --small-max-size: 1rem;
  --small-size-fluid: clamp(var(--small-min-size), calc(.87rem + .31vw), var(--small-max-size));

  // blockquote font size
  --blockquote-min-size: 1.35rem;
  --blockquote-max-size: 1.56rem;
  --blockquote-size-fluid: clamp(var(--blockquote-min-size), calc(1.09rem + 1.07vw), var(--blockquote-max-size));
}

$main-line-height: var(--main-line-height);
$main-size-fluid: var(--main-size-fluid);
$lead-size-fluid: var(--lead-size-fluid);
$small-size-fluid: var(--small-size-fluid);
$blockquote-size-fluid: var(--blockquote-size-fluid);

// menu font size
$menu-size: 1.6rem;
$menu-size-desktop: 2rem;

// select with label font size
$select-size: 1.15rem;
