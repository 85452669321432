@use '../Abstracts' as lib;

// =================================
// ===== DONATION SHOP ELEMENT =====
// =================================

.donation-shop {
  @include lib.set-content-width(lib.$med-content-width);

  &__header {
    text-align: center;

    @include lib.set-content-width(lib.$min-content-width);

    h2 {
      margin-bottom: lib.$size-32;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: lib.$size-16;
    color: lib.$col-text;

    > span {
      display: flex;
      align-items: flex-end;
      height: inherit;
    }

    strong {
      display: block;
      margin-bottom: lib.$size-16;
      color: lib.$col-blue;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: lib.$size-16;
    list-style-type: none;

    @include lib.breakpoint-min(sm) {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }

    &--3columns {
      @include lib.breakpoint-min(md) {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }
    }

    &--4columns {
      @include lib.breakpoint-min(md) {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }

      @include lib.breakpoint-min(lg) {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
      }
    }
  }

  &__item {
    position: relative;
    vertical-align: middle;
    box-sizing: border-box;
    margin: 0;
    border-radius: lib.rem(24px);
    box-shadow: 0 .5rem 1rem 0 lib.$col-black-20;
  }

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;

    img {
      border-top-left-radius: lib.rem(24px);
      border-top-right-radius: lib.rem(24px);
    }

    &.is-active {
      position: relative;

      &:before, &:after {
        content: '';
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: block;
        width: 24px;
        height: 24px;
      }

      &:before {
        border-radius: lib.rem(6px);
        background-color: lib.$col-white;
      }

      &:after {
        background-image: url('../Icons/check-blue.svg');
        background-position: center;
        background-size: 75% 75%;
        background-repeat: no-repeat;
      }
    }
  }

  &__basket {
    padding: lib.$size-16;
    border-radius: lib.rem(24px) !important;
    box-shadow: 0 .5rem 1rem 0 lib.$col-black-20 !important;
    margin-block: lib.$size-16;

    @include lib.breakpoint-min(sm) {
      padding: lib.$size-32 lib.$size-16;
    }

    img {
      width: 100%;
      max-width: 75px;
      padding-inline: lib.$size-8;
    }
  }

  &__basket-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__teaser {
    text-align: center;
    margin-block-end: lib.$size-32;
  }

  &__basket-amount {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;

    span {
      grid-row: 1;
      width: fit-content;
      color: lib.$col-blue;
      margin-inline-start: calc(#{lib.$size-24} * -1);

      @include lib.breakpoint-min(sm) {
        margin-inline-start: lib.$size-8;
      }
    }

    button {
      grid-row: 2;
      margin-block-start: lib.$size-16;

      @include lib.breakpoint-min(sm) {
        grid-row: 1;
        margin-block-start: 0;
        margin-inline-start: lib.$size-16;
      }
    }

    input {
      display: block;
      grid-row: 1;
      justify-self: center;
      width: fit-content;
      border: .1rem solid lib.$col-input-border !important;
      border-radius: .75em;
      background-color: lib.$col-white;
      color: lib.$col-blue;
      font-weight: bold !important;
      font-size: lib.$font-main-size-fluid !important;
      line-height: 0 !important;
      transition:
        border lib.$time ease-in-out,
        box-shadow lib.$time ease-in-out;
      padding-block: .5em;
    }
  }

  &__alternative-donation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    border: none !important;
    margin-block-start: lib.$size-24;

    span {
      margin-block-end: lib.$size-8;
    }

    button {
      text-align: center;
    }
  }
}
