@use 'swiper/swiper';
@use '../Abstracts' as lib;

// =================================
// ===== DONATION INFO ELEMENT =====
// =================================

.frame-type-plusdrkcontentelements_donation_info {
  margin-block: 0;
}

.donation-info {
  @include lib.breakpoint-min(md) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(8, 1fr);
  }

  @include lib.set-content-width(lib.$max-content-width);

  &__animation {
    img {
      margin-inline: auto;
    }

    @include lib.breakpoint-min(md) {
      grid-column: 1 / span 4;
      align-self: center;
    }

    @include lib.breakpoint-min(lg) {
      grid-column: 2 / span 4;
    }
  }

  &__animation-text {
    text-align: center;

    h2, h3, h4 {
      margin-bottom: lib.$size-8;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__text-box {
    height: fit-content;
    margin-top: lib.$size-64;
    padding: lib.$size-32;
    border-radius: 6px;
    text-align: center;

    @include lib.breakpoint-min(md) {
      grid-column: 6 / span 3;
      margin-top: lib.$size-32;
    }

    @include lib.breakpoint-min(lg) {
      grid-column: 7 / span 2;
    }

    h2, h3, h4 {
      color: lib.$col-headline;
    }

    h2, h3, h4, p {
      margin-bottom: lib.$size-16;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .small {
      color: lib.$col-text-shaded;
    }
  }

  &__gallery {
    width: 100vw;
    margin-top: lib.$size-64;

    @include lib.breakpoint-min(md) {
      grid-column: 1 / -1;
      width: 100%;
    }
  }

  // Slider
  &__swiper {
    width: 100%;

    .swiper-wrapper {
      @include lib.breakpoint-min(lg) {
        justify-content: space-between;
        transform: translateZ(0) !important;
      }
    }

    .swiper-slide {
      width: fit-content;

      &:not(:last-of-type) {
        margin-right: lib.$size-32;
      }
    }
  }
}
