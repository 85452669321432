@use '../Abstracts' as lib;

// =============================
// ===== Multimedia Header =====
// =============================

$-teaser-image-height: lib.rem(193.57px);
$-teaser-image-height-desktop: lib.rem(233.63px);

.frame-type-plusdrkcontentelements_multimedia_header {
  width: 100%;
  max-width: unset;
}

.multimedia-header {
  position: relative;
  width: 100%;

  &__media {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    aspect-ratio: 2 / 3;
    isolation: isolate;

    img, video, iframe {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    picture {
      display: contents;
    }

    @include lib.breakpoint-min(sm) {
      aspect-ratio: 3 / 2;
    }

    @include lib.breakpoint-min(lg) {
      aspect-ratio: 21 / 9;

      .multimedia-header--with-teaser & {
        aspect-ratio: 24 / 9;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-block: lib.$size-64 lib.$size-32;

    @include lib.breakpoint-min(lg) {
      padding-block: lib.$size-64;
    }

    @include lib.breakpoint-min(xl) {
      padding-block-start: lib.$size-128;
    }

    .multimedia-header--with-teaser & {
      padding-block-end: calc(#{lib.$size-32} + #{$-teaser-image-height});

      @include lib.breakpoint-min(sm) {
        padding-block-end: calc(#{lib.$size-32} + #{$-teaser-image-height-desktop});
      }
    }

    &.bg {
      background: linear-gradient(to top, rgb(0 0 0 / 80%), rgb(0 0 0 / 0%));
    }

  }

  &__text {
    @include lib.set-content-width(lib.$min-content-width);

    text-align: center;
    text-shadow: 0 0 lib.$size-16 lib.$col-black-100;

    h1, h2, h3, h4, .header__subheader {
      margin-block-end: lib.$size-16;

      + .multimedia-header__actions {
        margin-block-start: lib.$size-32;
      }
    }

    form {
      display: contents;

      .button {
        margin-block-end: 1em;
      }
    }

    .button {
      margin-inline: .5em;
      text-align: center;
      text-shadow: none;
    }
  }

  &__teaser {
    display: flex;
    gap: lib.$size-16;
    align-items: stretch;
    overflow-x: auto;
    max-width: max-content;
    margin-top: $-teaser-image-height * -1;
    margin-inline: auto;
    padding-inline: lib.$size-32;
    padding-block-end: lib.$size-32;

    @include lib.breakpoint-min(sm) {
      gap: lib.$size-32;
      margin-top: $-teaser-image-height-desktop * -1;
    }
  }
}

.header-teaser {
  position: relative;
  overflow: hidden;
  width: 290px;
  min-width: 290px;
  border-radius: lib.$size-24;
  box-shadow: 0 lib.$size-8 lib.$size-32 rgb(0 0 0 / 10%);
  text-align: center;

  @include lib.breakpoint-min(sm) {
    width: 350px;
    min-width: 350px;
  }

  p {
    margin-block-end: 0;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__content {
    padding-block: lib.$size-16 lib.$size-24;
    padding-inline: lib.$size-24;
  }

  &__title {
    color: lib.$col-headline;
    line-height: 1.2;
  }

  &__description {
    margin-block-start: lib.$size-8;
    line-height: 1.1;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
