// ==================
// ===== MIXINS =====
// ==================

// clearfix after float
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// Force break long strings
// @author Chris Coyier
// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin word-wrap {
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-all; // Fallback
  word-break: break-word;
  word-wrap: break-word;
}
