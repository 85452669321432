@use 'rem-utility' as lib;

$toolbar-height: lib.rem(80px);
$submenubar-height: lib.rem(48px);

// =========================
// ===== Global spaces =====
// =========================

// Global base padding that should be based on spaces from design (gutters, ...)

$size-8: lib.rem(8px);
$size-12: lib.rem(12px);
$size-16: lib.rem(16px);
$size-24: lib.rem(24px);
$size-32: lib.rem(32px);
$size-48: lib.rem(48px);
$size-64: lib.rem(64px);
$size-128: lib.rem(128px);


// ==========================
// ===== Content Widths =====
// ==========================

$side-padding: lib.rem(32px);

// content columns from design

$min-content-width: lib.rem(640px);
$med-content-width: lib.rem(960px);
$max-content-width: lib.rem(1280px);
