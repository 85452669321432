@use '../Abstracts' as lib;

// =========================
// ===== Language Menu =====
// =========================

.language-menu {
  position: relative;

  @media (min-width: 380px) {
    min-width: lib.rem(150px);
  }

  @include lib.breakpoint-min(sm) {
    min-width: lib.rem(160px);
  }

  @include lib.breakpoint-min(lg) {
    margin-top: 0;
  }

  .footer & {
    @include lib.breakpoint-max(md) {
      margin-block-start: lib.$size-16;
    }
  }

  &__select {
    display: block;
    padding-block: lib.$size-8 !important;
    padding-inline: lib.$size-48 2em !important;
    border: none;
    background-image: url('../Icons/chevron-black-80.svg') !important;
    background-position: center right .8em !important;
    color: lib.$col-text !important;
    font-size: lib.$font-small-size-fluid;
  }

  img {
    position: absolute;
    top: 50%;
    left: lib.$size-8;
    display: block;
    object-fit: cover;
    width: lib.$size-32;
    min-width: lib.$size-32;
    height: lib.$size-32;
    border-radius: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.language-suggest {
  position: fixed;
  top: 0;
  left: 0;
  z-index: lib.$z-index-language-suggest;
  width: 100%;
  padding: lib.$size-16;
  font-size: lib.$font-small-size-fluid;

  &__content {
    display: flex;
    flex-direction: column;
    gap: lib.$size-16;
    align-items: center;
    max-width: lib.$max-content-width;
    margin-inline: auto;

    @media (min-width: 380px) {
      flex-direction: row;
    }

    @include lib.breakpoint-min(sm) {
      gap: lib.$size-32;
    }
  }

  &__message {
    margin-block-end: .2em;

    &--suggest {
      color: lib.$col-black-20;
    }
  }

  &__actions {
    display: flex;
    gap: lib.$size-12;
    width: 100%;
    white-space: nowrap;

    @media (min-width: 380px) {
      flex-direction: column;
    }

    .language-menu {
      flex: 2 2 auto;
    }
  }

  &__close {
    flex: 1 1 auto;
    border-radius: .75em;
    text-align: center;
  }

  @include lib.breakpoint-min(md) {
    &__text {
      flex: 1;
    }

    &__actions {
      flex: 0;
      flex-direction: row;
    }
  }
}
