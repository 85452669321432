@use 'sass:math';
@use 'sass:string';
@use '../Abstracts' as lib;
//@use 'swiper/swiper';
@use '../../../../../../node_modules/swiper/swiper' as swiper;

// ================
// ===== News =====
// ================
.frame-list-type-news_pi1 {
  width: auto;
  max-width: unset;


  header {
    @include lib.set-content-width(lib.$min-content-width);
  }
}

.news {
  &__container {
    &--grey {
      padding-block: lib.$size-64;
    }
  }

  &__content {
    &--grey {
      padding: lib.$size-16 lib.$size-16 lib.$size-32;
      border-bottom-right-radius: lib.rem(24px);
      border-bottom-left-radius: lib.rem(24px);

      @include lib.breakpoint-min(sm) {
        padding: lib.$size-32 lib.$size-32 lib.$size-48;
      }
    }
  }

  &__article {
    display: grid;
    grid-template-rows: minmax(200px, 270px) 1fr;
    grid-template-columns: 1fr;
  }

  &__media {
    .news__container--grey & {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-block-end: 0;
    }

    img, a {
      height: 100%;
    }
  }

  &__title {
    font-size: lib.$font-main-size-fluid;

    &--link {
      .news__content--grey & {
        color: lib.$col-dark-blue;

        &:hover {
          text-decoration-color: lib.$col-dark-blue;
        }
      }
    }
  }

  &__metadates {
    margin-bottom: lib.$size-8;
  }

  &__date {
    color: lib.$col-headline;
    font-weight: bold;
  }

  &__category {
    color: lib.$col-text-shaded;
  }

  &__swiper {
    @include lib.breakpoint-max(sm) {
      padding-inline: lib.$size-32;

      .swiper-wrapper {
        height: auto;
      }

      .swiper-slide {
        width: 100%;
        max-width: 310px;
      }
    }
  }
}

// detailview
.newsdetail {
  &__header {
    overflow: hidden;
    margin-block-end: lib.$size-32;
  }

  &__metadates {
    position: relative;
    z-index: lib.$z-index-default * 2;
    color: lib.$col-red;
    font-weight: bold;
    text-align: center;
    margin-block-end: lib.$size-8;

    @include lib.set-content-width(lib.$min-content-width);
  }

  &__headline {
    position: relative;
    z-index: lib.$z-index-default * 2;
  }

  &__media {
    @include lib.set-content-width(lib.$med-content-width);

    position: relative;
    z-index: lib.$z-index-default;

    &:after {
      content: '';
      position: absolute;
      top: -2rem;
      left: -100vw;
      z-index: lib.$z-index-below;
      width: 200vw;
      height: 100%;
      background-image: linear-gradient(lib.$col-white, lib.$col-black-05);

      @include lib.breakpoint-min(sm) {
        top: -4rem;
      }
    }

    &--caption {
      &:after {
        top: -9rem;

        @include lib.breakpoint-min(sm) {
          top: -8rem;
        }
      }
    }
  }

  &__video {
    border-radius: 6px;
  }

  &__image {
    margin: 0;

    img {
      border-radius: 6px;
    }
  }

  &__image-caption {
    padding: lib.$size-8 0;
    border-bottom: 1px solid lib.$col-black-20;
    color: lib.$col-black-60;
    font-size: lib.$font-small-size-fluid;
    text-align: center;
    margin-block-end: lib.$size-16;

    @include lib.breakpoint-min(sm) {
      padding: lib.$size-8 lib.$size-16;
    }

    @include lib.set-content-width(lib.$min-content-width);
  }

  &__text {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &__category {
    position: relative;
    z-index: lib.$z-index-default * 2;
    display: flex;
    gap: lib.$size-8;
    overflow-x: auto;
    max-width: max-content;
    padding-inline: lib.$size-32;
    margin-inline: auto;
    margin-block-end: lib.$size-16;
  }

  &__button {
    min-width: fit-content;
    padding: lib.$size-8 lib.$size-16;
    border-radius: lib.rem(48px);
    background-color: lib.$col-black-05;
    color: lib.$col-blue;
    font-size: lib.$font-small-size-fluid;
    line-height: normal;
  }

  h1 {
    color: var(--col-headline-news);
  }

}

// swiper
.news-swiper__media {
  @include lib.breakpoint-min(sm) {
    display: none;
  }
}

.news-grid__media {
  display: none;

  @include lib.breakpoint-min(sm) {
    display: block;
  }
}

// Pagination
.f3-widget-paginator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: lib.$size-32;
  border-top: 1px solid lib.$col-black-20;

  .teaser__grid & {
    margin-top: lib.$size-32;
    border-top: 1px solid lib.$col-black-20;
  }

  .teaser__slider & {
    margin-top: lib.$size-32;
    border-top: 1px solid lib.$col-black-20;

    @include lib.set-content-width(lib.$min-content-width);
  }


  li {
    width: auto;
  }

  input {
    display: inline-block;
    width: lib.$size-64;
    padding: lib.$size-8;
    text-align: right;
  }


  .previous .button {
    padding: .45em .7em .45em .6em;
  }

  .next .button {
    padding: .45em .6em .45em .7em;
  }

  svg {
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    margin-top: .25rem;
    transform-origin: center;
  }

  .previous svg {
    transform: rotate(90deg);
  }

  .next svg {
    transform: rotate(-90deg);
  }
}

// Search
.news-search-result {

  .news__article {
      border-bottom: 1px solid var(--col-black-05);
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
}
