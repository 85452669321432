@use '../Abstracts' as lib;

// ===================
// ===== TOOLBAR =====
// ===================

.toolbar {
  display: flex;
  flex-flow: row nowrap;
  gap: lib.$size-16;
  align-items: center;
  justify-content: space-between;
  max-width: lib.$max-content-width;
  height: lib.$toolbar-height;
  margin: 0 auto;

  &__wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: lib.$z-index-toolbar;
    overflow: hidden;
    width: 100%;
    padding: 0 lib.$size-16;
    border-bottom: 1px solid lib.$col-black-20;
    background-color: lib.$col-white;
    transition: transform lib.$time lib.$timing-function;

    .hidden-toolbar & {
      transform: translateY(-100%);
    }
  }

  &__navigation {
    display: flex;
    flex-grow: 1;
    gap: lib.$size-16;
    align-items: center;
    justify-content: center;
  }

  &__search-button {
    width: lib.$size-32;
    height: lib.$size-32;
    padding: lib.$size-8 * .5;

    &:focus-visible {
      box-shadow: 0 0 0 lib.rem(2px) lib.$col-white, 0 0 0 lib.rem(5px) lib.$col-black-60;
      outline: none;
    }

    @include lib.breakpoint-min(lg) {
      display: block !important;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__donate-btn {
    margin: 0 !important;
    font-size: lib.$font-small-size-fluid;
    font-weight: 500;
    transition: transform lib.$time lib.$timing-function;

    @include lib.breakpoint-min(lg) {
      padding: .7em 1.2em .9em;
    }
  }

  &__nav-search-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: lib.$size-48;
    min-width: lib.$size-48;
    height: lib.$size-48;
    margin-left: lib.$size-8;
    border: 2px solid transparent;
    border-radius: 50%;
    color: lib.$col-black-100;
    transition: border-color 300ms ease-in-out;

    &:focus-visible {
      box-shadow: 0 0 0 lib.rem(2px) lib.$col-white, 0 0 0 lib.rem(5px) lib.$col-black-60;
      outline: none;
    }

    @include lib.breakpoint-min(lg) {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: lib.$col-red-gradient;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    .toolbar--menu--open & {
      &:before {
        opacity: 1;
      }
    }

    svg {
      display: inline-block;
      overflow: visible;
      width: lib.$size-32;
      max-width: 100%;
      height: lib.$size-48;
      fill: currentColor;
      transition:
        opacity 300ms ease-in-out,
        width 300ms ease-in-out;

      .toolbar--menu--open & {
        width: 0;
        opacity: 0;
      }
    }

    span {
      position: relative;
      display: block;
      width: lib.$size-48;
      height: lib.$size-48;
      margin-left: lib.$size-16 * -.8;
      transition:
        margin-left 300ms ease-in-out,
        width 400ms ease-in-out;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: lib.$size-12;
        height: 3px;
        background-color: lib.$col-black-100;
        transition:
          transform 400ms ease-in-out,
          right 300ms ease-in-out;
      }

      &:before {
        transform: translateY(calc(-50% - #{6px}));
      }

      &:after {
        transform: translateY(-50%);
      }

      .toolbar--menu--open & {
        margin-left: 0;

        &:before,
        &:after {
          background-color: lib.$col-white;
        }

        &:before {
          right: 50%;
          width: lib.$size-16;
          transform: translate(50%, -50%) rotate(-45deg);
        }

        &:after {
          right: 50%;
          width: lib.$size-16;
          transform: translate(50%, -50%) rotate(45deg);
        }
      }
    }
  }
}
