// ==================
// ===== Colors =====
// ==================

:root {
  // Red tones
  --col-red: #e60005;
  --col-dark-red: #a51e0f;
  --col-red-gradient: linear-gradient(to bottom right, var(--col-dark-red), var(--col-red));

  // Blue tones
  --col-blue: #2276d0;
  --col-dark-blue: #002d55;
  --col-blue-gradient: linear-gradient(to bottom right, var(--col-dark-blue), var(--col-blue));

  // Waterrescue
  --col-waterrescue-blue: #008ccd;

  // youth red cross
  --col-youthredcross-petrol: #00a0aa;

  // sisterhoof
  --col-sisterhood-blue: #041e42;

  // Grey tones
  --col-black-100: rgb(0 0 0);
  --col-black-80: rgb(51 51 51);
  --col-black-60: rgb(102 102 102);
  --col-black-20: rgb(203 203 203);
  --col-black-40: rgb(153 153 153);
  --col-black-05: rgb(242 242 242);

  // White tones
  --col-white: rgb(255 255 255);
  --col-white-80: rgb(255 255 255 / 80%);
  --col-white-60: rgb(255 255 255 / 60%);
  --col-white-20: rgb(255 255 255 / 20%);
  --col-white-05: rgb(255 255 255 / 5%);

  // Functional colors
  --col-background: var(--col-white);
  --col-text: var(--col-black-80);
  --col-text-highlight: var(--col-blue);
  --col-text-shaded: var(--col-black-60);
  --col-button-border: var(--col-blue);
  --col-button-background: var(--col-blue-gradient);
  --col-button-text: var(--col-white);
  --col-link: var(--col-blue);
  --col-link-underline: var(--col-blue);
  --col-link-hover: var(--col-blue);
  --col-headline: var(--col-red);
  --col-blockquote: var(--col-red);
  --col-input-label: var(--col-black-80);
  --col-input-placeholder: var(--col-black-60);
  --col-input-border: var(--col-black-20);
  --col-radio-button: var(--col-blue);
  --col-radio-button-border: var(--col-black-20);
}


// Red tones
$red: var(--col-red);
$dark-red: var(--col-dark-red);
$red-gradient: var(--col-red-gradient);

// Blue tones
$blue: var(--col-blue);
$dark-blue: var(--col-dark-blue);
$blue-gradient: var(--col-blue-gradient);

// Water rescue
$waterrescue-blue: var(--col-waterrescue-blue);

// Youth red cross
$youthredcross-petrol: var(--col-youthredcross-petrol);

// Youth red cross
$sisterhood-blue: var(--col-sisterhood-blue);

// Grey tones
$black-100: var(--col-black-100);
$black-80: var(--col-black-80);
$black-60: var(--col-black-60);
$black-40: var(--col-black-40);
$black-20: var(--col-black-20);
$black-05: var(--col-black-05);

// White tones
$white: var(--col-white);
$white-80: var(--col-white-80);
$white-60: var(--col-white-60);
$white-20: var(--col-white-20);
$white-05: var(--col-white-05);

// Functional colors
$background: var(--col-background);
$text: var(--col-text);
$text-highlight: var(--col-text-highlight);
$text-shaded: var(--col-text-shaded);
$button-border: var(--col-button-border);
$button-background: var(--col-button-background);
$button-text: var(--col-button-text);
$link: var(--col-link);
$link-underline: var(--col-link-underline);
$link-hover: var(--col-link-hover);
$headline: var(--col-headline);
$blockquote: var(--col-blockquote);
$input-label: var(--col-input-label);
$input-placeholder: var(--col-input-placeholder);
$input-border: var(--col-input-border);
$radio-button: var(--col-radio-button);
$radio-button-border: var(--col-radio-button-border);
