@use '../Abstracts' as lib;

// ======================
// ===== Breadcrumb =====
// ======================

.breadcrumb {
  padding-bottom: lib.$size-32;
  text-align: center;

  &__list {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }

  &__entry {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__arrow {
    width: lib.$size-12;
    height: lib.$size-12;
    margin-top: lib.$size-8;
    transform-origin: center;
    color: lib.$col-black-20;
  }

  @include lib.breakpoint-min(sm) {
    line-height: 1.8em;

    &__list {
      display: block;
    }

    &__item {
      display: inline;
    }

    &__entry {
      display: inline;
      margin-inline-end: lib.$size-8;
    }

    &__arrow {
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      transform: rotate(-90deg) translateX(.1em);
      margin-inline-end: lib.$size-12;
    }
  }
}
