@use '../Abstracts' as lib;

// ===============================
// ===== Default Page Layout =====
// ===============================

.body__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: lib.$toolbar-height + lib.$submenubar-height + lib.$size-32;
}

// ==================
// ===== Header =====
// ==================

// #header { }

// =====================
// ===== Main Area =====
// =====================

main {
  flex-grow: 1;

  > .frame:first-child {
    &.frame-type-plusdrkcontentelements_multimedia_header {
      margin-block-start: 0;
    }
  }

  > :where(*) {
    @include lib.set-content-width;

    &:last-child {
      margin-bottom: lib.$size-128;
    }
  }
}

// =================================
// ===== Category Pages Layout =====
// =================================

.pagets__category {
  .page-category {
    margin-block-end: lib.$size-16;

    @include lib.set-content-width(lib.$min-content-width);

    @include lib.breakpoint-min(sm) {
      margin-block-end: lib.$size-32;
    }

    &__button {
      display: inline-block;
      min-width: fit-content;
      padding: lib.$size-8 lib.$size-16;
      border-radius: lib.rem(48px);
      background-color: lib.$col-black-05;
      color: lib.$col-blue;
      font-size: lib.$font-small-size-fluid;
      line-height: normal;
      text-shadow: none;
      margin-inline-end: lib.$size-8;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


// ===============================
// ===== System Pages Layout =====
// ===============================

#system-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  color: white;
  text-align: center;

  p {
    max-width: lib.rem(800px);
    margin: 0 auto;
  }
}
