// ====================
// ===== Headings =====
// ====================

:root {
  // Based on https://royalfig.github.io/fluid-typography-calculator/
  // Min Viewport: 57.9rem (390px)
  // Max Viewport: 120rem (704px)

  --headings-line-height: 1.1;
  --h1-min-size: 2.33rem;
  --h1-max-size: 3.05rem;
  --h1-size-fluid: clamp(var(--h1-min-size), calc(1.44rem + 3.67vw), var(--h1-max-size));
  --h2-size-fluid: var(--h1-size-fluid);
  --h3-min-size: 1.94rem;
  --h3-max-size: 2.44rem;
  --h3-size-fluid: clamp(var(--h3-min-size), calc(1.32rem + 2.55vw), var(--h3-max-size));
  --h4-min-size: 1.62rem;
  --h4-max-size: 1.95rem;
  --h4-size-fluid: clamp(var(--h4-min-size), calc(1.21rem + 1.68vw), var(--h4-max-size));
}

$headings-line-height: var(--headings-line-height);
$h1-size-fluid: var(--h1-size-fluid);
$h2-size-fluid: var(--h2-size-fluid);
$h3-size-fluid: var(--h3-size-fluid);
$h4-size-fluid: var(--h4-size-fluid);
