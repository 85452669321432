@use 'sass:map';

// =======================
// ===== Breakpoints =====
// =======================

// based on 16px pattern
// reused by gridlex
$breakpoints: (
  xxl: 1400px,
  xl: 1200px,
  lg: 992px,
  md: 768px,
  sm: 576px,
  xs: 370px
);

@mixin breakpoint-min($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: #{map-get($breakpoints, $breakpoint) + 1}) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @error 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin breakpoint-max($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @error 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}
