@use '../Abstracts/index' as lib;

// ====================
// ===== BASETAGS =====
// ====================

html {
  // Set our base font-size to % and use relative units to handle
  // all other font-size rules in our CSS. This allows users to change
  // the base font size in their browser.

  font-size: 100%;
  -webkit-text-size-adjust: 100%;

  // enable smooth scrolling
  scroll-behavior: smooth;
}

body {
  background-color: lib.$col-background;
  color: lib.$col-text;
  font-family: lib.$font-main, sans-serif;
  line-height: lib.$font-main-line-height;
  font-size: lib.$font-main-size-fluid;

  &.prevent-scroll {
    overflow: hidden;
  }
}

img, picture, video {
  display: block;
  max-width: 100%;
  height: auto;
  border: none;
}

video.video-embed-item { margin-inline: auto; }

iframe {
  display: block;
  margin-inline: auto;
  border: none;
}
