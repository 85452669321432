@use '../Abstracts' as lib;
@use '../../../../../../node_modules/swiper/swiper' as swiper;
@use '../../../../../../node_modules/swiper/modules/navigation/navigation' as swiper-navigation;
@use '../../../../../../node_modules/swiper/modules/pagination/pagination' as swiper-pagination;
@use '../../../../../../node_modules/swiper/modules/a11y/a11y' as swiper-a11y;

// ======================
// ===== Text Media =====
// ======================

.frame-type-textmedia {
  width: auto;
  max-width: 100%;

  .frame-list-type-powermail_pi1 & {
    margin-block: lib.$size-32;
  }
}

.textmedia {
  display: flex;
  flex-direction: column;

  @include lib.set-content-width(lib.$med-content-width);

  .frame-list-type-powermail_pi1 & {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &--heading-only {
    h1, h2, h3, h4 {
      &:not(.header__header--withsubheader) {
        margin-bottom: 0;
      }
    }
  }

  &__figure {
    margin: 0 0 lib.$size-48;

    a {
      display: block;
    }
  }

  &__image {
    width: 100%;

    @media screen and (min-width: lib.$min-content-width) {
      border-radius: lib.rem(6px);
    }

    &--original-size {
      width: auto !important;
      max-width: 100% !important;
      margin-inline: auto !important;
    }
  }

  &__video {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__audio {
    width: 100%;
  }

  &__caption {
    padding: lib.$size-8 0;
    border-bottom: 1px solid lib.$col-black-20;
    color: lib.$col-text-shaded;
    font-size: lib.$size-16;
    text-align: center;

    &:empty {
      display: none;
    }
  }

  &__text {
    margin-block-end: 1rem;

    @include lib.set-content-width(lib.$min-content-width);
  }
}

// === orientation center ==
.textmedia--orientation-above, .textmedia--orientation-below {
  .textmedia__figure {
    @media screen and (min-width: lib.$min-content-width) {
      @include lib.set-content-width(lib.$med-content-width);
    }
  }

  .textmedia__image {
    width: 100vw;
    max-width: unset;
    margin-inline: lib.$side-padding * -1;

    @media screen and (min-width: lib.$min-content-width) {
      width: 100%;
      margin-inline: 0;
    }
  }

  .textmedia__caption {
    @include lib.set-content-width(lib.$min-content-width);
  }
}

.textmedia--orientation-below {
  .textmedia__figure,
  .textmedia__gallery {
    order: 2;
  }
}

// === orientation beside ===
.textmedia.textmedia--orientation-beside {
  @include lib.set-content-width(lib.$max-content-width);

  .textmedia__image {
    width: 100vw;
    max-width: unset;
    margin-inline: lib.$side-padding * -1;

    @media screen and (min-width: lib.$min-content-width) {
      margin-inline: 0;
      width: 100%;
    }
  }

  @media screen and (min-width: lib.$min-content-width) {
    &.textmedia--portrait {
      .textmedia__figure,
      .textmedia__gallery {
        max-width: 420px;
        margin-inline: auto;
      }
    }
  }

  @media screen and (min-width: lib.$med-content-width) {
    .textmedia {
      &__container {
        display: inline-flex;
        align-items: flex-start;

        .frame-textmedia-beside--reverse & {
          flex-direction: row-reverse;
        }
      }

      &__figure,
      &__gallery,
      &__text {
        width: 50%;
      }

      &__text {
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-inline-start: lib.$size-64;

        .frame-textmedia-beside--reverse & {
          padding-inline: 0 lib.$size-64;
        }

        .header {
          width: unset;
          max-width: unset;
          margin-inline: unset;
          text-align: left;
        }
      }
    }

    &.textmedia--portrait {
      .textmedia__figure,
      .textmedia__gallery {
        width: 33.33%;
      }

      .textmedia__text {
        width: 66.66%;
      }
    }
  }
}

// === gallery ===
.textmedia__gallery {
  position: relative;
  margin-inline: lib.$side-padding * -1;
  margin-block-end: lib.$size-32;

  @media screen and (min-width: lib.$min-content-width) {
    margin-inline: 0;
  }

  .textmedia__figure {
    width: 100% !important;
    margin-block-end: 0;
    padding-block-end: lib.$size-32;
  }

  .textmedia__image {
    width: 100% !important;
    max-width: unset !important;
    margin: 0 !important;
  }

  .textmedia__image--original-size {
    width: auto !important;
    max-width: 100% !important;
    margin-inline: auto !important;
  }

  .swiper {
    .swiper-wrapper { height: auto; }

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        background: lib.$col-black-20;

        &.swiper-pagination-bullet-active {
          background: lib.$col-dark-blue;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: lib.$size-48;
    height: lib.$size-48;
    background-color: lib.$col-black-05;
    margin: 0;
    transform: translateY(-50%);

    &:after { content: none; }

    svg {
      width: lib.$size-12;
      height: auto;
      margin: 0;
      path { fill: lib.$col-dark-blue; }
    }

    @media screen and (min-width: lib.$min-content-width) {
      display: flex;
    }
  }
}

// === fullwidth parallax-effect ===
.frame-type-textmedia .textmedia--orientation-fullwidth {
  position: relative;
  width: 100%;
  max-width: unset;
  margin: 5vmax 0;

  .textmedia {
    &__image {
      position: sticky;
      top: lib.rem(80px);
      z-index: 0;
      object-fit: cover;
      width: 100%;
      height: 100vh;
      border-radius: 0;
    }

    &__text-wrap {
      position: relative;
      width: 100%;
      padding: lib.$size-32 0 20vh 0;
      background: linear-gradient(180deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 80%));

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
        transform: translateY(-100vh);
      }
    }

    &__text {
      grid-column: min-content-width;
      color: lib.$col-white;
      text-shadow: 0 0 lib.$size-16 lib.$col-black-100;

      @include lib.set-content-width(lib.$min-content-width);

      h1, h2 {
        color: lib.$col-white;
      }
    }

    &__caption {
      padding: lib.$size-8 0 0 0;
      border-top: 1px solid lib.$col-white-80;
      border-bottom: 0;
      color: lib.$col-white-80;
      font-size: lib.$font-small-size-fluid;
      text-align: left;

      span:before {
        margin-inline: .3em .5em;
      }

      span:nth-child(1):before {
        content: '|';
      }

      span:nth-child(2):before {
        content: '-';
        margin-inline-start: .5em;
      }
    }
  }
}

// === expandable ===
.textmedia__expandable {
  position: relative;
  overflow: hidden;
  max-height: 15em;
  margin-bottom: 0;
  transition: all lib.$time lib.$timing-function;

  .textmedia__unfold {
    position: absolute;
    z-index: lib.$z-index-default;
    display: none;
    margin-inline: auto;
    padding-block: lib.$size-16;
    color: lib.$col-link;
    font-weight: bold;
    font-size: lib.$font-small-size-fluid;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    inset-inline: 0;
    inset-block-end: 0;

    &:after {
      content: '';
      position: relative;
      display: inline-block;
      width: lib.$size-8;
      height: lib.$size-8;
      margin-inline: lib.$size-8;
      border: .125rem solid currentColor;
      border-top-width: 0;
      border-right-width: 0;
      transform: translateY(-.25rem) rotate(-45deg);
    }
  }

  &--folded {
    margin-bottom: 1em;
    mix-blend-mode: darken;

    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 6.4em;
      background: linear-gradient(0deg, lib.$col-white 30%, transparent 100%);
      pointer-events: none;
      inset-inline: 0;
      inset-block-end: 0;
    }

    :where(.bg--blue, .bg--grey, .bg--red) & {
      mix-blend-mode: lighten;

      &:after {
        background: linear-gradient(0deg, lib.$col-black-100 30%, transparent 100%);
      }
    }

    .textmedia__unfold {
      display: inline-block;
    }
  }
}

// Textmedia in a ccordion
// Images next to the text should be over the text in an accordion
.ce-acco__panel {
  .frame-type-textmedia.frame-textmedia-beside {
    .textmedia {
      @include lib.set-content-width(lib.$med-content-width);

      .textmedia__container {
        @media screen and (min-width: lib.$med-content-width) {
          display: flex;
          flex-direction: column;

          .textmedia__gallery,
          .textmedia__figure {
            width: 100%;
          }

          .textmedia__text {
            @include lib.set-content-width(lib.$min-content-width);

            display: flex;
            flex-direction: column;
            padding-left: 0;
          }
        }
      }
    }
  }
}
