@use 'sizes';
@use 'functions';
@use 'sass:math';

// ============================
// ===== Width Containers =====
// ============================

// Mixin for grid
@mixin set-content-width($width: sizes.$min-content-width) {
  // client-vw is calculated by JS with fallback of 1 vw
  width: $width;
  max-width: calc(var(--client-vw, 100vw) - #{2 * sizes.$side-padding});
  margin-left: auto;
  margin-right: auto;
}

.min-content-width { @include set-content-width(sizes.$min-content-width); }
.med-content-width { @include set-content-width(sizes.$med-content-width); }
.max-content-width { @include set-content-width(sizes.$max-content-width); }

.full-width {
  width: auto;
  max-width: unset;
  margin-right: 0;
  margin-left: 0;
}
