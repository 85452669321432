@use '../Abstracts' as lib;

// ================================
// ===== CONTACT FORM ELEMENT =====
// ================================

.frame-list-type-powermail_pi1 {
  .powermail_check_inner,
  .powermail_radio_inner {
    display: flex;
    margin-block-end: lib.$size-16;

    input {
      top: 0;
    }

    label {
      margin-bottom: 0;
    }
  }

  .powermail_radio_inner {
    align-items: center;
  }

  .powermail_fieldwrap {
    .parsley-required,
    .parsley-type {
      color: lib.$col-dark-red;
      font-size: lib.$font-small-size-fluid;
    }
  }

  .powermail_fieldwrap_text,
  .powermail_fieldwrap_date {
    margin-block-end: lib.$size-16;
  }

  .powermail_fieldwrap_submit {
    display: flex;
    justify-content: flex-end;

    input {
      width: fit-content;
    }
  }

  .powermail_fieldwrap_select,
  .powermail_fieldwrap_input {
    position: relative;
  }

  .powermail_fieldwrap_input {
    margin-block-end: lib.$size-16;

    input {
      padding-top: lib.rem(28px);
      padding-bottom: .5em;
    }
  }

  .powermail_fieldwrap_check,
  .powermail_fieldwrap_radio {
    label {
      position: revert;
    }
  }

  .o-form__field--select {
    padding-top: lib.rem(28px);
    font-size: lib.$font-select-size;
    margin-block-end: lib.$size-16;
  }

  .powermail_label {
    position: absolute;
    top: lib.$size-8;
    left: lib.$size-16;
    line-height: 1.2;
    pointer-events: none;
    padding-inline-end: lib.$size-32;

    @include lib.breakpoint-min(xs) {
      padding-inline-end: lib.$size-24;
    }
  }

  // stylelint-disable-next-line
  #powermail_field_marker {
    display: block;
    margin-block-start: lib.$size-32;

    h3 {
      margin-block-end: lib.$size-16;
    }
  }

  // confirmation page
  .powermail_confirmation {
    .o-form__item {
      margin-block-end: lib.$size-32;

      p {
        font-weight: bold;
        margin-block-end: lib.$size-16;
        margin-inline-start: lib.$size-16;
      }

      .powermail_all_value {
        padding: lib.$size-12;
        border: lib.rem(2px) solid lib.$col-black-20;
        border-radius: lib.rem(24px);

        @include lib.breakpoint-min(sm) {
          padding: lib.$size-16;
        }
      }
    }

    .btn-group {
      display: flex;
      justify-content: space-between;

      .button {
        width: fit-content;
      }
    }
  }
}
