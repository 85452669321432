@use '../Abstracts' as lib;

// ====================================
// ===== DONATION SUMMARY ELEMENT =====
// ====================================

.summary {
  margin-bottom: lib.$size-32;

  &__header {
    position: relative;
    z-index: lib.$z-index-default;
    color: lib.$col-white;
    text-align: center;
    padding-block: lib.$size-32;

    @include lib.set-content-width(lib.$min-content-width);

    &--wrap {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -100vw;
        width: 150vw;
        height: 100%;
        background: linear-gradient(to bottom right, #{lib.$col-dark-red}, #{lib.$col-red});
        transform: translateX(50vw);
      }
    }
  }

  &__item {
    margin-bottom: lib.$size-32;
  }


  &__title {
    p {
      margin-bottom: lib.$size-16;
      font-weight: bold;
      margin-inline-start: lib.$size-16;
    }
  }

  &__data {
    padding: lib.$size-8;
    border: lib.rem(2px) solid lib.$col-black-20;
    border-radius: lib.rem(24px);

    @include lib.breakpoint-min(sm) {
      padding: lib.$size-16;
    }

    &--button-look {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    &--amazon {
      padding: 0;
      border: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    button {
      /* stylelint-disable-next-line */
      #card_token-form & {
        margin-block-end: lib.$size-32;
      }
    }
  }
}
