@use '../Abstracts' as lib;

// =================================
// ===== DONATION FORM ELEMENT =====
// =================================

.frame-list-type-drkdonations_donation {
  padding-block: lib.$size-32;

  @include lib.breakpoint-min(sm) {
    padding-block: lib.$size-64;
  }
}

/* stylelint-disable-next-line */
#card_token-form, #formTab {
  @include lib.set-content-width(lib.$min-content-width);

  select {
    padding: 1em .5em 1em .8em !important;
  }

  label {
    @include lib.word-wrap;
  }

  input {
    top: 0;
  }

  fieldset {
    margin-bottom: 0;
  }

  .o-error-list__item {
    margin-top: lib.$size-8;
    color: lib.$col-dark-red;
    margin-inline-start: lib.$size-8;
  }
}

/* stylelint-disable-next-line */
#card_pan, #card_cvc {
  position: relative;
  width: 100%;

  span {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(#{lib.$size-24} * -1);
    width: 100%;
    color: lib.$col-black-60;
    font-weight: bold;
    font-size: lib.$font-main-size-fluid;
    font-family: lib.$font-main;
    pointer-events: none;
    transform: translateY(-52%);
  }

  &.has-value {
    span {
      display: none;
    }
  }

  label {
    display: none;
  }
}

.donation-form {
  p {
    text-align: center;
  }

  &__header {
    text-align: center;
    padding-block: lib.$size-32;

    span {
      display: block;

      @include lib.set-content-width(lib.$min-content-width);
    }
  }

  &__item {
    margin-bottom: lib.$size-32;

    &--sepa,
    &--creditcard {
      margin-bottom: lib.$size-16;
      position: relative;
    }

    &--purpose {
      svg {
        width: 2rem;
        height: 2rem;
        margin-top: lib.$size-16;
      }
    }

    &--submit {
      display: flex;
      justify-content: flex-end;
    }

    &--amount {
      margin-bottom: lib.$size-24;
    }
  }

  &__headline {
    margin-bottom: lib.$size-16;
    text-align: center;

    &--red {
      margin-bottom: lib.$size-12;
      color: lib.$col-red;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    margin-top: lib.$size-16;
  }

  &__info-button {
    display: flex;
    width: fit-content;

    /* stylelint-disable-next-line */
    #card_token-form & {
      margin-inline: auto;
      margin-block-end: 0;
      margin-block-start: lib.$size-16;
    }
  }

  &__custom-amount {
    input {
      padding: 1em .8em;
    }
  }

  &__amount {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__amount-button {
    position: relative;

    /* stylelint-disable-next-line */
    #card_token-form & {
      margin-block-end: 1em;
      margin-block-start: lib.$size-12;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1.5rem;
      background-color: transparent;
      transition: box-shadow lib.$time ease;

      &:hover, &:checked {
        background-color: transparent;
      }

      &:hover {
        box-shadow: none;
      }

      &:checked {
        box-shadow: inset  0 0 0 .1rem lib.$col-white, 0 0 0 .15rem lib.$col-blue;
      }
    }

    label {
      margin-bottom: 0;
      color: lib.$col-white;
    }
  }

  &__frequency, &__type {
    border: 1px solid lib.$col-black-20;
    border-radius: 16px;
  }

  &__frequency-item, &__type-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: lib.$size-24 lib.$size-16;

    &:not(:first-of-type) {
      border-top: 1px solid lib.$col-black-20;
    }

    &:hover {
      label {
        color: lib.$col-blue;
      }
    }

    label {
      width: 100%;
      margin-bottom: 0;
      font-size: lib.$font-main-size-fluid;
      cursor: pointer;
      transition: color lib.$time ease;

      &.active {
        color: lib.$col-blue;
      }
    }
  }

  &__personal-data {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: lib.$size-16;
  }

  &__data-item {
    &--salutation,
    &--persontitle,
    &--firstname,
    &--lastname {
      grid-column: span 1;
    }

    &--company,
    &--email,
    &--tax,
    &--tax-data,
    &--newsletter {
      grid-column: span 2;
    }

    &--tax {
      span {
        display: block;
        margin-top: lib.$size-24;
        margin-bottom: lib.$size-16;
        text-align: center;
      }
    }

    &--tax-data {
      display: grid;
      row-gap: lib.$size-16;
      margin-bottom: lib.$size-8;
    }
  }

  &__tax, &__newsletter {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: lib.$size-24 lib.$size-16;
    border: 1px solid lib.$col-black-20;
    border-radius: 16px;

    label {
      width: 100%;
      margin-right: lib.$size-16;
      margin-bottom: 0;
      font-size: lib.$font-main-size-fluid;
    }
  }

  &__type-headline {
    text-align: center;
  }

  &__payment-item {
    position: relative;
    display: flex;
    margin-bottom: lib.$size-16;
    padding: lib.$size-24 lib.$size-16;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: transparent;

      &:hover, &:checked {
        background-color: transparent;
        box-shadow: none;
      }
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0;

      .container {
        margin-right: lib.$size-16;
        color: lib.$col-black-60;
        font-size: lib.$font-small-size-fluid;
      }

      .title {
        display: block;
        margin-right: 1em;
        color: lib.$col-black-80;
        font-size: lib.$font-main-size-fluid;
      }
    }
  }

  &__hint {
    margin-bottom: lib.$size-16;

    strong {
      &:not(&:last-of-type) {
        color: lib.$col-dark-red;
      }
    }

    span {
      margin-bottom: 1em;
    }
  }

  &__error {
    margin-bottom: lib.$size-16;
  }

  &__error-item {
    margin-bottom: lib.$size-16;
    color: lib.$col-dark-red;
    text-align: center;
  }

  &__payment-button {
    /* stylelint-disable-next-line */
    #card_token-form & {
      min-height: 120px;
      margin-right: 0;
    }

    &--inactive {
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    > input {
      border: none !important;
    }
  }
}
