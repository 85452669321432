@use '../Abstracts' as lib;

// =================================
// ===== DONATION DEED ELEMENT =====
// =================================

.donation-deed {
  @include lib.set-content-width(lib.$med-content-width);

  .blue {
    margin-block-end: lib.$size-16;
  }

  .black {
    min-height: unset !important;
    max-height: unset !important;
    padding: lib.$size-32 !important;
    margin-block-end: lib.$size-32;

    .gift,
    .document,
    .material {
      &.show {
        position: revert !important;
        display: flex;
        justify-content: center;
        transform: none !important;

        .personal.visible {
          margin-top: lib.rem(187px) !important;
        }
      }
    }
  }

  &__headline {
    position: relative;
    line-height: calc(#{lib.$font-headings-line-height} * 1.1) !important;
  }

  &__icon {
    position: absolute;
    display: inline-block;
  }

  &__motive {
    width: 225px !important;
  }

  &__motive,
  &__option {
    &.active {
      .fa-check {
        top: -7px;
        right: 3px;
        width: 1rem;

        &:before {
          content: url('../Icons/check-white.svg');
          margin-block: lib.$col-text-shaded;
        }
      }
    }
  }

  &__personalise {
    display: flex;
    flex-direction: column;
    gap: lib.$size-16;
    padding: lib.$size-32 lib.$size-16 !important;

    @include lib.breakpoint-min(md) {
      flex-direction: row;
      padding: lib.$size-32 !important;
    }

    > div {
      float: unset !important;
      width: 100% !important;
      padding: 0 !important;

      @include lib.breakpoint-min(md) {
        width: 50% !important;
      }
    }

    .first {
      > div {
        margin-block-end: lib.$size-16;
      }
    }

    strong {
      color: lib.$col-text !important;
    }

    .count {
      @include lib.breakpoint-max(md) {
        bottom: - lib.$size-8 !important;
      }
    }
  }

  &__message {
    @include lib.breakpoint-max(md) {
      margin-block-end: lib.$size-32 !important;
    }
  }

  &__amount {
    &:before {
      display: none !important;
    }
  }

  &__button {
    float: right !important;
    width: unset !important;

    button:after {
      display: none !important;
    }
  }

  &__error {
    font-size: lib.$font-small-size-fluid;
  }
}
