@use '../Abstracts' as lib;

// =================
// ===== STEPS =====
// =================

.steps {
  margin-bottom: lib.$size-16;

  @include lib.set-content-width(lib.$min-content-width);

  @include lib.breakpoint-min(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  span {
    position: relative;
    color: lib.$col-black-20;
    margin-bottom: lib.$size-8;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include lib.breakpoint-min(sm) {
      flex-direction: row;

      svg {
        transform: rotate(-90deg) translate(0, .5rem);
      }
    }

    &.active {
      color: lib.$col-text;
    }
  }
}
