@use '../Abstracts' as lib;

// =================================
// ===== TOPICS TEASER ELEMENT =====
// =================================

.frame-type-plusdrkcontentelements_topics_teaser {
  width: unset;
  max-width: unset;

  > header {
    text-align: center;

    @include lib.set-content-width(lib.$min-content-width);
  }
}

.topics-teaser {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: lib.$size-16;

  @include lib.breakpoint-min(md) {
    @include lib.set-content-width(lib.$max-content-width);
  }

  .bg--red &,
  .bg--blue &,
  .bg--grey &,
  .bg--lightgrey & {
    @include lib.set-content-width(lib.$max-content-width);
  }

  @include lib.breakpoint-min(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: lib.$size-32;
  }

  &__title {
    display: block;
    margin-bottom: lib.$size-8;

    .topics-teaser__text--white & {
      text-shadow: 0 0 lib.$size-16 lib.$col-black-100;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;

    @include lib.breakpoint-min(md) {
      border-radius: 6px;
      box-shadow: 0 5px 20px -5px lib.$col-black-60;
    }

    .bg--red &,
    .bg--blue &,
    .bg--lightgrey & {
      border-radius: 6px;
      box-shadow: 0 5px 20px -5px lib.$col-black-80;
    }

    .bg--grey & {
      border-radius: 6px;
      box-shadow: 0 5px 20px -5px lib.$col-white-20;
    }


    &--50-top,
    &--50-bottom,
    &--50-below-white {
      grid-column: span 1;
    }

    &--100-left,
    &--66-left,
    &--66-right {
      grid-column: span 1;

      @include lib.breakpoint-min(md) {
        grid-column: span 2;
      }
    }

    &--66-left,
    &--66-right {
      display: grid;

      .topics-teaser__text {
        grid-row: 2;
      }

      @include lib.breakpoint-min(md) {
        grid-template-columns: repeat(3, minmax(auto, 500px));

        .topics-teaser__text {
          display: grid;
          grid-row: 1;
          align-content: center;
          height: 100%;
        }
      }
    }

    &--66-left {
      @include lib.breakpoint-min(md) {
        .topics-teaser__image {
          grid-column: 2 / span 2;
        }

        .topics-teaser__text {
          grid-column: 1 / span 1;
        }
      }
    }

    &--66-right {
      @include lib.breakpoint-min(md) {
        .topics-teaser__image {
          grid-column: 1 / span 2;
        }

        .topics-teaser__text {
          grid-column: 3 / span 1;
        }
      }
    }
  }

  &__image {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      .topics-teaser__item--100-left & {
        @include lib.breakpoint-max(sm) {
          min-height: 550px;

          .bg--red &,
          .bg--blue &,
          .bg--grey &,
          .bg--lightgrey & {
            min-height: 650px;
          }
        }
      }

      .topics-teaser__item--50-below-white & {
        aspect-ratio: 4 / 3;
      }
    }

    picture {
      display: contents;
    }

    .topics-teaser__item--50-top &,
    .topics-teaser__item--50-bottom & {
      height: 100%;
    }

    .topics-teaser__item--100-left &,
    .topics-teaser__item--66-left &,
    .topics-teaser__item--66-right & {
      max-height: 500px;

      .bg--red &,
      .bg--blue &,
      .bg--grey &,
      .bg--lightgrey & {
        max-height: 650px;
      }

      @include lib.breakpoint-min(md) {
        max-height: 700px;
      }
    }
  }

  &__text {
    width: 100%;
    padding: lib.$size-24;

    p {
      text-shadow: 0 0 lib.$size-16 lib.$col-white;
    }

    &--white {
      p {
        text-shadow: 0 0 lib.$size-16 lib.$col-black-100;
      }
    }

    @include lib.breakpoint-min(lg) {
      padding: lib.$size-64;
    }

    &--50-top,
    &--50-bottom,
    &--100-left {
      position: absolute;
    }

    &--50-top,
    &--50-bottom,
    &--50-below-white {
      @include lib.breakpoint-min(md) {
        text-align: center;
      }
    }

    &--50-top,
    &--50-bottom {
      z-index: lib.$z-index-default;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        z-index: lib.$z-index-below;
        width: 100%;
        height: 133%;
      }
    }

    &--50-top {
      top: 0;

      &:after {
        top: 0;
        background: linear-gradient(to bottom, rgb(255 255 255 / 60%) 0%, rgb(255 255 240 / 0%) 100%);
      }

      &.topics-teaser__text--white {
        &:after {
          background: linear-gradient(to top, rgb(255 255 255 / 0%) 0%, rgb(0 0 12 / 60%) 100%);
        }
      }
    }

    &--50-bottom {
      bottom: 0;
      justify-content: flex-end;

      &:after {
        bottom: 0;
        background: linear-gradient(0deg, rgb(255 255 255 / 60%) 0%, rgb(255 255 240 / 0%) 100%);
      }

      &.topics-teaser__text--white {
        &:after {
          background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(0 0 12 / 60%) 100%);
        }
      }
    }

    &--100-left {
      top: 0;
      z-index: lib.$z-index-default;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: lib.$z-index-below;
        display: block;
        width: 133%;
        height: 100%;
        background: linear-gradient(90deg, rgb(255 255 255 / 60%) 0%, rgb(255 255 240 / 0%) 100%);
      }

      &.topics-teaser__text--white {
        &:after {
          background: linear-gradient(-90deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 12 / 60%) 100%);
        }
      }

      @include lib.breakpoint-min(md) {
        max-width: lib.rem(578px);
      }
    }
  }

  &__button {
    width: fit-content;
    margin-right: lib.$size-8 !important;
  }
}
