@use 'sass:math';
@use 'sass:string';
//@use 'swiper/swiper';
//@use 'swiper/modules/a11y/a11y';
//@use 'swiper/modules/effect-fade/effect-fade';
@use '../../../../../../node_modules/swiper/swiper' as swiper;
@use '../../../../../../node_modules/swiper/modules/a11y/a11y' as swiper-a11y;
@use '../../../../../../node_modules/swiper/modules/effect-fade/effect-fade' as effect-fade;

@use '../Abstracts' as lib;

// ========================
// ===== GRIDELEMENTS =====
// ========================
.grid__fullwidth { width: 100%; }

.frame.gridelements__wrapper {
  max-width: unset;
  width: auto;

  header {
    margin-bottom: lib.$size-32;

    @include lib.breakpoint-min(sm) { margin-bottom: lib.$size-64; }
  }

  > header {
    @include lib.set-content-width(lib.$min-content-width);
  }

  .frame {
    margin-top: 0;
    margin-bottom: lib.$size-16;

    @include lib.breakpoint-min(sm) {
      margin-top: 0;
      margin-bottom: lib.$size-32;
    }

    header {
      margin-bottom: 0;

      @include lib.breakpoint-min(sm) { margin-bottom: 0; }
    }
  }
}

.gridelements__body {
  @include lib.set-content-width(lib.$max-content-width);
  .gridelements__body { width: 100%; }
  .gridelements__body .frame { margin-bottom: 0; }
  .frame { margin: 0; }

  .frame header {
    width: 100%;
    max-width: 40rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.gridelements {
  &__slider {
    width: unset;
    max-width: unset;

    img {
      max-width: unset !important;
    }

    .frame, .stage-image {
      width: unset;
      max-width: unset;
      margin: 0;
    }
  }
}

.header-slider--maritim-strip {
  position: relative;
  isolation: isolate;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: lib.$size-24;
    height: 100%;
    background-color: lib.$col-white;
    z-index: 1;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;

    // A stripe with repeating colors blue and white
    background-image:
      repeating-linear-gradient(
        to bottom,
        #{lib.$col-waterrescue-blue},
        #{lib.$col-waterrescue-blue} #{lib.$size-24},
        #{lib.$col-white} #{lib.$size-24},
        #{lib.$col-white} #{lib.$size-24 * 2}
      );

    @include lib.breakpoint-min(sm) {
      width: lib.$size-48;
      background-image:
        repeating-linear-gradient(
          to bottom,
          #{lib.$col-waterrescue-blue},
          #{lib.$col-waterrescue-blue} #{lib.$size-48},
          #{lib.$col-white} #{lib.$size-48},
          #{lib.$col-white} #{lib.$size-48 * 2}
        );
    }
  }
}

.gridblock__item,
.gridblock__item--large,
.gridblock__item--small {
  .frame-type-textmedia .textmedia {
    overflow: hidden;
  }

  .frame-type-textmedia .textmedia,
  .frame-type-textmedia .textmedia__figure,
  .frame-type-textmedia .textmedia__text,
  .frame-type-textmedia .textmedia__caption,
  header, iframe {
    width: 100% !important;
    max-width: unset !important;
  }
}

/* 50:50 */
.gridblock__wrapper {
  display: grid;

  // grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
  gap: lib.$size-32;
}

.gridblock__wrapper.gridblock--3columns,
.gridblock__wrapper.gridblock--4columns,
.gridblock__wrapper--66-33,
.gridblock__wrapper--75-25 {
  display: flex;
  gap: unset;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}

// 33:33:33
.gridblock__wrapper.gridblock--3columns {
  .gridblock__item {
    // originally min-width: 45.0625em -> 721px
    @media screen and (min-width: 640px) {
      width: calc((100% - 2rem) / 2);
      margin-right: lib.$size-32;
      &:nth-of-type(2n) { margin-right: 0; }
    }

    // originally min-width: 90.0625em -> 1441px
    @media screen and (min-width: 1440px) {
      width: calc((100% - 4rem) / 3);
      margin-right: lib.$size-32;
      &:nth-of-type(2n) { margin-right: lib.$size-32; }
      &:nth-of-type(3n) { margin-right: 0; }
    }
  }
}

// 25:25:25:25
.gridblock__wrapper.gridblock--4columns {
  .gridblock__item {
    // originally min-width: 45.0625em
    @media screen and (min-width: 640px) {
      width: calc((100% - 32px) / 2);
      margin-right: lib.$size-32;
      &:nth-of-type(2n) { margin-right: 0; }
    }

    // originally min-width: 90.0625em
    @media screen and (min-width: 992px) {
      width: calc((100% - 96px) / 4);
      margin-right: lib.$size-32;
      &:nth-of-type(2n) { margin-right: lib.$size-32; }
      &:nth-of-type(4n) { margin-right: 0; }
    }
  }
}

// 66:33
.gridblock__wrapper--66-33 {
  .gridblock__item {
    &.gridblock__item--large {
      // originally min-width: 64.0625em
      @media screen and (min-width: 768px) {
        width: calc((100% / 3) * 2 - 16px);
        margin-right: lib.$size-16;
      }
    }

    &.gridblock__item--small {
      @media screen and (min-width: 768px) {
        width: calc(100% / 3 - 16px);
        margin-right: 0;
        margin-left: lib.$size-16;
      }
    }
  }
}

// 75:25
.gridblock__wrapper--75-25 {
  .gridblock__item {
    &.gridblock__item--large {
      @media screen and (min-width: 768px) {
        width: calc((100% / 4) * 3 - 16px);
        margin-right: lib.$size-16;
      }
    }

    &.gridblock__item--small {
      @media screen and (min-width: 768px) {
        width: calc(100% / 4 - 16px);
        margin-right: 0;
        margin-left: lib.$size-16;
      }
    }
  }
}
