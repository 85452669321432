@use '../Abstracts' as lib;
@use 'swiper/swiper';

// =====================================
// ===== DONATION CAROUSEL ELEMENT =====
// =====================================

.donation-carousel {
  padding: lib.$size-64 0;

  &__head {
    text-align: center;

    @include lib.set-content-width(lib.$min-content-width);

    h2, p {
      margin-bottom: lib.$size-32;
    }
  }

  &__item {
    border-radius: lib.rem(24px);
  }

  &__image {
    margin-bottom: lib.$size-32;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: lib.$size-48 lib.$size-32;
    border-radius: lib.rem(24px);
    box-shadow: 10px 6px 12px 0 rgb(0 0 0 / 15%);

    @include lib.breakpoint-min(md) {
      padding: lib.$size-128;
    }
  }

  &__text {
    text-align: center;

    h4 {
      margin-bottom: lib.$size-8;
    }

    span {
      display: block;
      margin-bottom: lib.$size-16;
    }
  }

  &__button {
    font-size: lib.$font-small-size-fluid;
  }

  &__carousel {
    position: relative;

    @include lib.breakpoint-max(sm) {
      padding-inline: lib.$size-16;
    }

    @include lib.set-content-width(lib.$med-content-width);

    .swiper-button-prev,
    .swiper-button-next {
      width: lib.rem(48px);
      height: lib.rem(48px);
      border-radius: 50%;
      background-color: lib.$col-white;

      &:after, &:before {
        content: '';
        position: absolute;
        width: 12px;
        border-bottom: 4px solid lib.$col-red;
        border-radius: 2px;
      }

      &:before {
        top: 19px;
      }

      &:after {
        bottom: 19px;
      }

      @include lib.breakpoint-max(md) {
        display: none;
      }
    }

    .swiper-button-prev {
      &:after {
        left: 17px;
        transform: rotate(45deg);
      }

      &:before {
        left: 17px;
        transform: rotate(-45deg);
      }
    }

    .swiper-button-next {
      &:after {
        left: 19px;
        transform: rotate(-45deg);
      }

      &:before {
        left: 19px;
        transform: rotate(45deg);
      }
    }
  }

  &__swiper {
    overflow: visible;

    .swiper-slide {
      max-width: 480px;

      .swiper-slide-shadow {
        border-radius: lib.rem(24px);
      }
    }
  }
}
