@use '../Abstracts' as lib;

// ===============================
// ===== FACTS TILES ELEMENT =====
// ===============================

.facts {
  @include lib.breakpoint-min(sm) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    border-bottom: 1px solid lib.$col-black-20;
    padding-block: lib.$size-24;


    @include lib.breakpoint-min(sm) {
      border-right: 1px solid lib.$col-black-20;
      border-bottom: transparent;
      border-bottom: 1px solid lib.$col-black-20;
      padding-block: lib.$size-24;
      padding-inline-end: lib.$size-24;

      &--1,
      &--2 {
        padding-block-start: 0;
      }
    }

    &:nth-child(4n + 1),
    &:nth-child(4n + 4) {
      @include lib.breakpoint-min(sm) {
        grid-column: auto / span 2;
      }
    }

    &:nth-child(4n + 2),
    &:nth-child(4n + 4) {
      border-right: transparent;
    }

    &:nth-child(4n + 1) {
      @include lib.breakpoint-min(sm) {
        border-right: 1px solid lib.$col-black-20;
      }
    }

    &:nth-child(4n + 2) {
      @include lib.breakpoint-min(sm) {
        padding-inline-start: lib.$size-24;
        padding-inline-end: 0;
      }
    }

    &:nth-child(4n + 4) {
      @include lib.breakpoint-min(sm) {
        padding-inline-start: lib.$size-24;
        padding-inline-end: 0;
      }
    }
  }

  &__text {
    font-weight: bold;
    font-size: lib.$font-h4-size-fluid;
    line-height: lib.$font-headings-line-height;

    @include lib.breakpoint-min(sm) {
      font-size: lib.$font-lead-size-fluid;

      .facts__item:nth-child(4n + 1) &,
      .facts__item:nth-child(4n + 4) & {
        font-size: lib.$font-h4-size-fluid;
      }
    }

    p {
      margin-bottom: lib.$size-8;
    }
  }

  &__prefix {
    margin-block-end: lib.$size-8;
  }

  &__suffix {
    margin-block-end: 0;
  }

  &__red {
    color: lib.$col-red;
  }
}
