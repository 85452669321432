@use '../Abstracts/index' as lib;

// ===================================
// ===== Project Partner Element =====
// ===================================

.frame-type-plusdrkcontentelements_project_partner {
  width: 100%;
  max-width: unset;
  background-color: lib.$col-black-05;
  padding-block: lib.$size-32;

  @include lib.breakpoint-min(md) {
    padding-block: lib.$size-64;
  }
}

.project-partner {
  // eslint-disable-next-line
  @include lib.set-content-width(lib.$med-content-width);

  &__sister,
  &__partner {
    display: flex;
    gap: lib.$size-16;
    overflow-y: scroll;
    padding-block-end: lib.$size-16;

    @include lib.breakpoint-min(sm) {
      gap: lib.$size-32;
      padding-block-end: lib.$size-24;
    }

    span {
      max-width: 400px;
      text-align: center;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 220px;
    padding: lib.$size-24;
    border-radius: 6px;
    background-color: lib.$col-white;

    @include lib.breakpoint-min(sm) {
      min-width: 290px;
      padding: lib.$size-32;
    }

    img {
      object-fit: contain;
      height: 100%;
      margin-block-end: lib.$size-16;
    }
  }

  &__title {
    display: block;
    color: lib.$col-black-40;
    margin-block-end: lib.$size-8;
  }

  &__sister {
    margin-block-end: lib.$size-8;
    padding-block-end: lib.$size-16;

    @include lib.breakpoint-min(sm) {
      padding-block-end: lib.$size-24;
    }

    img {
      max-height: 250px;
    }
  }

  &__partner {
    img {
      max-height: 200px;
    }
  }
}
