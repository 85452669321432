@use 'sass:math';
@use 'sass:string';
@use '../Abstracts/index' as lib;

$blogwidth-m: 52.5rem;
$blogwidth-l: 60rem;
$blogwidth-xl: 80rem;

.blog__list,
.blog__teaser {
  .teaser__media {
    border-radius: 0;
    box-shadow: none;
    transform: none !important;
  }
  .teaser__media img {
    transition: transform .5s;
  }
  .teaser__media:active img,
  .teaser__media:focus img,
  .teaser__media:hover img {
    transform: scale(1.05);
  }
  .news__date,
  .news__category,
  .news__author {
    color: var(--col-black-40);
    font-size: .9rem;
    line-height: 120%;
    display: inline-block;
  }
  .news__title--link:active,
  .news__title--link:focus,
  .news__title--link:hover {
    color: lib.$col-link;
  }
  .footer {
    overflow: initial;
  }
}

/* Blog list */
.blog__list {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--client-vw, 100vw) - 2rem);
  width: $blogwidth-xl;

  .teaser__item {
    overflow: hidden;
  }

  .teaser__item.bg--grey {
    background-color: var(--col-background);
    color: var(--col-text);
  }

  & > .teaser__item:first-child {
    width: 100%;
  }
  .news__title--link {
    color: var(--col-text);
    display: block;
    font-size: var(--h4-size-fluid);
    line-height: calc(var(--headings-line-height) * 1.1);
    margin-bottom: 0.5rem;
    text-decoration: none;
  }
  .bg--grey {
    .news__title--link:active,
    .news__title--link:focus,
    .news__title--link:hover {
      text-decoration: underline;
    }
  }
  .teaser__content {
    padding: .5rem 1rem 3.5rem 1.5rem;
  }
  .news__date,
  .news__category,
  .news__author {
    font-size: 1rem;
    line-height: 120%;
  }
  .bg--grey {
    .news__date,
    .news__category,
    .news__author {
      color: var(--col-text);
    }
  }
  .news-list-more {
    margin: 1rem 1rem 0 0;
  }
  .blog__sublist {
    width: 100%;
  }
  @media (min-width: 450px) {
    .teaser__item {
      border-radius: .5rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.1);
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 768px) {
    &.teaser__grid .teaser__block {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    }
    .teaser__item {
      align-items: start;
      background-color: #ffffff;
      display: inline-block;
      justify-items: center;
      overflow: hidden;
    }
    & > .teaser__item:first-child {
      .teaser__content {
        padding: 3rem 4rem 4rem 4rem;
      }
    }
    .blog__sublist {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2.5rem;
      padding-bottom: 10rem;

      .teaser__item {
        width: 100%;

        &:first-child + .teaser__item {
          top: 10rem;
          margin-bottom: 7rem;
        }

        &:nth-of-type(even) {
          top: 5rem;
          position: relative;
        }
      }
    }
  }
  @media (min-width: 1000px) {
    & > .teaser__item:first-child {
      align-items: stretch;
      align-content: center;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 6rem;
      width: 100%;

      .teaser__media {
        flex-basis: 66.666%;
        margin-block-end: 0;
        min-height: 100%;

        * {
          width: auto;
          min-height: 100%;
          height: 100%;
        }

        img {
          aspect-ratio: 776/558;
        }
      }
      .teaser__content {
        align-self: center;
        flex-basis: 33.333%;
        padding: 3rem;
      }
      .teaser__text > div {
        display: block;
        display: -webkit-box;
        line-height: 2rem;
        max-height: 14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
      }
    }
    .teaser__content {
      padding: 3rem 4rem 4rem 4rem;
    }
  }
}

/* Blog as teaser */
.blog__teaser {
  &.teaser__grid {
    display: block !important;
  }
  .teaser__item {
    background-color: #ffffff;
    border-radius: 0;
    box-shadow: none;
    overflow: hidden;
  }
  .news__media--link {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 10;

    &:active:before,
    &:focus:before {
      -webkit-border-top-left-radius: .5rem;
      -webkit-border-top-right-radius: .5rem;
      -moz-border-radius-topleft: .5rem;
      -moz-border-radius-topright: .5rem;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;

      border: 3px solid var(--col-link);
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 15;
    }
  }
  .news__title {
    margin-bottom: .8em;
  }
  .teaser__content {
    padding: .5rem 1rem 6.5rem 1.5rem;
    position: relative;
  }
  .news__date,
  .news__category,
  .news__author,
  .news__title--link {
    color: #000000;
    display: inline-block;
  }
  .news__title--link {
    color: #000000;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
  .teaser__text {
    font-size: 1rem;
    line-height: 150%;
  }
  .footer {
    bottom: 2.5rem;
    position: absolute;
    width: calc(100% - 5rem);
  }
  .news-list-more {
    border: 2px solid lib.$col-link;
    border-radius: 1.5rem;
    color: lib.$col-link;
    display: inline-block;
    font-size: 1rem;
    line-height: 120%;
    padding: 0.5em 1em;
    margin: 0 1rem 0 0;
    text-decoration: none;
  }

  @media (min-width: 450px) {
      .teaser__item {
        border-radius: .5rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.1);
      }
      .teaser__content {
        padding: 1rem 2.5rem 6.5rem 2.5rem;
      }
    }
}

/* Mobil for blog list and teaser*/
@media (max-width: 450px) {
  .blog__list,
  .blog__teaser.teaser__grid {
    max-width: calc(var(--client-vw, 100vw));
  }
}

/* Blog Detail */
.newsdetail.blog {
  margin-top: -2.1rem;
  overflow: hidden;

  .newsdetail__header {
    margin-block-end: 2rem;
  }
  .newsdetail__header .newsdetail__media:after {
    display: none !important;
  }
  .newsdetail__header .newsdetail__media {
    margin-block-end: 5.125rem;
    max-width: calc(var(--client-vw, 100vw));
    width: $blogwidth-xl;
  }
  .newsdetail__header .newsdetail__image img {
    border-radius: 0;
    max-height: 600px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
  .newsdetail__headlineWrap {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(var(--client-vw, 100vw) - 2rem);
    width: $blogwidth-xl;
  }
  .newsdetail__headline {
    flex: 40% 0 1;
    margin: 0;
    max-width: 100%;
    text-align: left;
    width: 100%;
  }
  h1 {
    margin-bottom: 1.5rem;
  }
  .newsdetail__teaser {
    flex: 58% 0 1;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.313rem;
  }
  .newsdetail__meta {
    color: #666666;
    font-size: 1rem;
  }
  .newsdetail__text {
    max-width: calc(var(--client-vw, 100vw) - 2rem);
    overflow: hidden;
    width: $blogwidth-xl;

    .textmedia__text,
    .textmedia__text header,
    .textmedia.textmedia--text-only {
      max-width: 100%;
      width: $blogwidth-m;
    }
    .textmedia--orientation-above {
      display: flex;

      .textmedia__text:first-child {
        margin-bottom: 0 !important;
        order: 2;

        header {
          text-align: left;
        }
        h2 {
          margin-bottom: 2rem;
        }
      }
      .textmedia__figure {
        order: 1;
      }
      .textmedia__text {
        order: 3;
      }
    }

    .frame-type-textmedia + .frame-type-plusdrkcontentelements_quote {
      margin-top: -4rem;
    }

    .frame-type-plusdrkcontentelements_quote {
      margin: 0 auto;
      max-width: 100%;
      width: $blogwidth-m;

      + .frame-type-textmedia .textmedia.textmedia--text-only {
        margin-top: -2rem;
      }
    }
  }
  .news-related-wrap {
    width: 100%;
  }

  #newsdetail__anchorMenu-wrapper {
    margin: 3rem 0;
    padding: 2rem 0;
    position: relative;
    width: 100%;

    &:before,
    &:after {
      border-bottom: solid var(--col-black-20) 1px;
      content: "";
      display: inline-block;
      width: 100%;
      position: absolute;
      top: 0;
    }
    &:after {
      top: auto;
      bottom: 0;
    }
  }
  #newsdetail__anchorMenu-innerWrap {
    margin: 2rem auto;
    max-width: 100%;
    width: $blogwidth-m;

    h2 {
      font-size: 1.5rem;
    }
  }
  #newsdetail__anchorMenu {
    a {
      display: inline-block;
      padding: 0.3em 1em 0.3em 1.5em;
      position: relative;
      text-decoration: none;

      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
    .arrow {
      border: solid var(--col-link);
      border-top-width: medium;
      border-right-width: medium;
      border-bottom-width: medium;
      border-left-width: medium;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4px;
      vertical-align: middle;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg) translateY(-50%);

      position: absolute;
      left: 0;
      top: 50%;
    }
  }

  /* Blog detail page - Related links */
  .news-related-links {
    margin: 0 auto 4rem ;
    max-width: calc(var(--client-vw, 100vw) - 2rem);
    width: $blogwidth-m;

    &.hasRelatedNews {
      padding-bottom: 2rem;
      position: relative;

      &:after {
        border-bottom: solid var(--col-black-20) 1px;
        content: '';
        display: inline-block;
        width: 100%;

        position: absolute;
        bottom: 0;
      }
    }
    h4 {
      font-size: 1.375rem;
    }
    ul {
      li {
        list-style: disc;
        margin-left: 1em;
        padding: 0.3em 1em 0.3em 0.5em;
        position: relative;
      }

      a {
        display: inline-block;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        display: block;
        width: 100%;
      }
    }
  }

  /* Blog detail page - Related news */
  .news-related-news {
    margin: 0 auto;
    max-width: calc(var(--client-vw, 100vw) - 2rem);
    width: $blogwidth-xl;

    h4 {
      text-align: center;
    }
  }

  .related-news__bloglist {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;

    li {
      max-width: 38.375rem;
      width: 100%;
    }
    .newsdetail__image--link {
      &:active img,
      &:focus img,
      &:hover img {
        transform: scale(1.05);
      }
      &:active .newsdetail__media,
      &:focus .newsdetail__media {
        box-shadow: 0 0 0 3px lib.$col-link;
      }
    }
    .newsdetail__media {
      box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.1);
      border-radius: .5rem;
      margin-bottom: 1.5rem;
      max-width: 100%;
      overflow: hidden;
      width: 100%;

      img {
        aspect-ratio: 3/2;
        border-radius: .5rem;
        object-fit: cover;
        object-position: center;
        transition: transform .5s;
      }
    }
    .newsdetail__image-caption {
      display: none;
      max-width: 100%;
    }
    h5 {
      font-size: 1.25rem;
      line-height: 140%;

      a {
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
         text-decoration: underline;
        }
      }
    }
    p {
      display: block;
      display: -webkit-box;
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 2rem;
      max-height: 14rem;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
    }
  }

  @media (min-width: 577px) {
      margin-top: -4.1rem;
  }

  @media screen and (min-width: 60rem) {

    .blog__list,
    .newsdetail__headlineWrap,
    .newsdetail__text,
    .news-related-links,
    .news-related-news {
      max-width: calc(var(--client-vw, 100vw) - 4rem);
    }
    .newsdetail__headlineWrap {
      display: flex;
      align-items: stretch;
      align-content: flex-start;
      justify-content: space-between;
    }
    .newsdetail__text {
      .textmedia.textmedia--orientation-beside .textmedia__figure,
      .textmedia.textmedia--orientation-beside .textmedia__gallery {
        width: 31.5%;
      }
    }
  }

  @media screen and (min-width: 80rem) {
    .newsdetail__header .newsdetail__image img {
      -webkit-border-bottom-right-radius: 0.75rem;
      -webkit-border-bottom-left-radius: 0.75rem;
      -moz-border-radius-bottomright: 0.75rem;
      -moz-border-radius-bottomleft: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }
  }
}