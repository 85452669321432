// ==========================
// ======= drk_donate =======
// ==========================

@use '../Abstracts' as lib;
@import '../Atoms/utility-classes';

.tx-drk-donate {
  .donation-form__amount-button {
    margin-block-end: 1em;
    margin-block-start: 0.75rem;
  }

  .button--donate {
    width: unset;
  }

}
.frame-type-drkdonate_donate {

  @include bg;
  @include bg--lightgrey;

  width: unset;
  max-width: unset;

  padding-top: lib.$size-32;
  padding-bottom: lib.$size-32;


}

.tx-drk-memeberform-verification {
  @include lib.set-content-width(lib.$med-content-width);
}
