@use '../Abstracts' as lib;

// ==========================
// ===== LIBRARY LAYOUT =====
// ==========================

.library {
  margin-top: lib.$size-128;

  &__label {
    display: block;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .bg {
    padding-top: lib.$size-32;
    padding-bottom: lib.$size-32;
    margin-bottom: lib.$size-32;

    &.grid .bg {
      padding-right: lib.$size-32;
      padding-left: lib.$size-32;
    }
  }
}
