@use '../Abstracts' as lib;

// ===========================
// ===== Image Copyright =====
// ===========================

.image-copyright {
  @include lib.word-wrap;

  width: calc(100% - #{lib.$side-padding} * 2);
  max-width: lib.$med-content-width;
  height: 100vh;
  margin-block-start: lib.$size-64;
  margin-block-end: 0;
  padding: 0;
  border-width: 0;
  border-top-left-radius: lib.$size-24;
  border-top-right-radius: lib.$size-24;
  box-shadow: 0 lib.rem(3px) lib.$size-64 rgb(0 0 0 / 20%);
  font-size: lib.$font-small-size-fluid;

  @media (max-width: 600px) {
    width: 100%;
    margin: 36px 0 0;
  }

  &:not([open]) {
    display: none;
  }

  &::backdrop {
    background: linear-gradient(to top, #666 0%, white 100%);
    cursor: zoom-out;
  }

  &__content {
    position: relative;
  }

  &__container {
    overflow: auto;
    max-width: lib.$min-content-width;
    max-height: calc(100vh - #{lib.$size-64} * 2);
    margin-inline: auto;
    margin-block-start: lib.$size-64;
    padding: lib.$size-32;

    @include lib.breakpoint-min(md) {
      padding-block-start: 0;
    }
  }

  &__form {
    position: sticky;
    top: lib.$size-32;
    right: lib.$size-32;
  }

  &__close {
    position: absolute;
    top: lib.$size-32 * -1;
    right: lib.$size-32;
    display: inline-block;
    width: lib.$size-48;
    height: lib.$size-48;
    border-radius: 50%;
    background-color: lib.$col-white;
    transition: transform .2s ease-in-out;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: lib.$size-24;
      height: lib.rem(3px);
      background-color: lib.$col-black-80;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-135deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__title {
    margin-block-end: lib.$size-48;

    &:before {
      left: calc(50% - #{lib.$size-48} / 2);
    }
  }

  &__list {
    padding-inline-start: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: lib.$size-8;

    @include lib.breakpoint-min(md) {
      flex-direction: row;
      gap: lib.$size-16;
      align-items: center;
    }

    &:not(:last-child) {
      margin-block-end: lib.$size-24;
    }

    p {
      margin-block-end: 0;
      line-height: 1.2;
    }
  }

  &__image {
    max-width: 160px;
  }
}
