@use '../Abstracts' as lib;

// =================
// ===== Lists =====
// =================

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list.list--ordered {
  list-style: decimal;
  counter-reset: item;

  > li {
    counter-increment: item;
    display: block;
    position: relative;
    padding: .3em 1em .3em 1.7em;

    > ol > li {
      padding-left: 2.2em;
    }
  }

  > li:before {
    content: counters(item, '.') '. ';
    position: absolute;
    list-style: none;
    top: 0;
    left: 0;
    margin-left: -1.5em;
    padding: .3em 1em .3em 1.5em;
  }
}

.list.list--unordered {
  list-style: disc;

  > li {
    list-style: disc;
    position: relative;
    margin-left: 1em;
    padding: .3em 1em .3em .5em;

    > ul {
      list-style: circle;
      margin-left: 1em;
      padding: .3em 1em .3em 1.7em;

      > li {
        margin-left: -1.5em;
        padding: .3em 1em .3em 0;
      }
    }
  }

  > li:before {
    content: '';
    position: absolute;
    margin-left: -.5rem;
    padding: .3em 1em .3em 1.5em;
  }
}
