@use '../Abstracts' as lib;

// ==============================
// ===== ICON TILES ELEMENT =====
// ==============================

.frame-type-plusdrkcontentelements_icon_tiles {
  @include lib.set-content-width(lib.$max-content-width);
}

.icon-tiles {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: lib.$size-32;
  justify-items: center;
  border-top: 1px solid lib.$col-black-20;
  border-bottom: 1px solid lib.$col-black-20;
  padding-block: lib.$size-32;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    &--link {
      text-decoration: none;

      &:hover {
        text-decoration: none;

        .icon-tiles__icon {
          transform: scale(1.2);
        }

        .icon-tiles__topic {
          text-decoration: underline lib.$col-link;
        }
      }
    }
  }

  &__topic, &__description {
    text-align: center;
    margin-block-end: lib.$size-8;
  }

  &__description {
    .icon-tiles__item--link & {
      color: lib.$col-black-80;
    }
  }

  &__icon {
    transition: transform lib.$time ease-in-out;

    .icon-tiles__item--link & {
      margin-block-end: lib.$size-8;
    }
  }
}
