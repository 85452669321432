@use 'sass:color';
@use '../Abstracts' as lib;

// ===================
// ===== Buttons =====
// ===================

// reset all default styles
button,
.button {
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  overflow: visible; // Show the overflow in IE und Edge.
  text-transform: none; // Remove the inheritance of text transform in Firefox.
  -webkit-appearance: none; // Correct the inability to style clickable types in iOS and Safari.
  appearance: none;
  width: fit-content;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:not(:focus-visible) {
    outline: none;
  }
}

// CTA Buttons
.button,
.form-navigation .btn,
:where(button[type='submit']) {
  display: inline-block;
  border-radius: lib.rem(24px);
  cursor: pointer;
  font-size: inherit;
  font-weight: bold;
  gap: 1em;
  line-height: 1.2;
  padding: .5em 1em;
  text-align: start;
  text-decoration: none;
  transition-duration: lib.$time;
  transition-property: all;
  transition-timing-function: lib.$timing-function;

  &:not(:last-child) {
    margin: 0 1em 1em 0;
  }

  &:focus,
  &:hover {
    // will be box-shadow since safari does
    // not support outlines with radius
    outline: none;
    text-decoration: none;
  }
}

// Default Button
.button,
.button--primary,
.form-navigation .btn {
  color: lib.$col-button-text;
  background: lib.$col-button-background;

  &:hover, &:focus {
    background: lib.$col-button-background;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    box-shadow: 0 0 0 lib.rem(2px) lib.$col-button-text, 0 0 0 lib.rem(4px) lib.$col-button-border;
  }

  &.button--outline {
    border-color: lib.$col-button-border;
    background: transparent;
    color: lib.$col-button-text;

    &:hover, &:focus {
      background: lib.$col-button-background;
      color: lib.$col-button-text;
    }
  }

  &--small {
    padding: .3em .5em;
    font-size: lib.$font-small-size-fluid;
  }

  &--block {
    width: 100%;
  }
}


// Secondary Button
.button--secondary,
:where(button[type='submit']) {
  color: lib.$col-link;
  background: transparent;
  border: lib.rem(2px) solid lib.$col-button-border;

  &:hover, &:focus {
    background: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 lib.rem(2px) lib.$col-button-text, 0 0 0 lib.rem(4px) lib.$col-button-border;
  }
}

// Tertiary Button
.button--tertiary, a.o-btn {
  color: lib.$col-link;
  background: transparent;

  &:hover, &:focus {
    background: transparent;
  }

  &:after {
    content: '';
    background-color: transparent;
    background-image: url('../Icons/chevron-blue.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-.1em) rotate(-90deg);
    height: .6em;
    width: .6em;
    margin-left: .3em;
  }
}

.bg--red,
.bg--blue,
.bg--grey {
  .button--tertiary:after {
    background-image: url('../Icons/chevron-white.svg');
  }
}

.bg .bg--default {
  .button--tertiary:after {
    background-image: url('../Icons/chevron-blue.svg');
  }
}

// Spenden Button
.button--donate {
  color: lib.$col-white;
  background: lib.$col-red-gradient;

  &:hover, &:focus {
    background: lib.$col-red-gradient;
  }

  &:focus {
    box-shadow: 0 0 0 lib.rem(2px) lib.$col-button-text, 0 0 0 lib.rem(4px) lib.$col-red;
  }
}

// Disabled buttons (keep last)
.button {
  &:disabled,
  &--disabled {
    cursor: not-allowed;

    &:hover {
      animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: lib.rem(1000px);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.button--primary:disabled,
  &.button--primary.button--disabled {
    color: lib.$col-white;
    background: lib.$col-black-20;
  }

  &.button--secondary:disabled,
  &.button--secondary.button--disabled {
    color: lib.$col-black-20;
    border-color: lib.$col-black-20;
  }

  &.button--tertiary:disabled,
  &.button--tertiary.button--disabled {
    color: lib.$col-black-20;

    &:after {
      background-image: url('../Icons/chevron-grey.svg');
    }
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(lib.rem(-1px), 0, 0);
  }

  20%, 80% {
    transform: translate3d(lib.rem(2px), 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(lib.rem(-4px), 0, 0);
  }

  40%, 60% {
    transform: translate3d(lib.rem(4px), 0, 0);
  }
}

.bg--grey {
  .button {
    &.button--primary:disabled,
    &.button--primary.button--disabled {
      color: lib.$col-black-80;
    }

    &.button--secondary:disabled,
    &.button--secondary.button--disabled {
      color: lib.$col-text;
    }

    &.button--tertiary:disabled,
    &.button--tertiary.button--disabled {
      color: lib.$col-text;
    }
  }
}
