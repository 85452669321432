@use '../Abstracts' as lib;

// =================
// ===== Quote =====
// =================

.quote {
  margin: 0;
  padding: 0;
  border-top: solid lib.$col-black-20 1px;
  border-bottom: solid lib.$col-black-20 1px;

  span {
    display: block;
  }

  &__text {
    border: none;
    margin-block-end: 0;
  }

  &__info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-block-end: lib.$size-8;
  }

  &__cite {
    margin-inline-end: lib.$size-8;
    font-style: normal;
    line-height: 1.2;
    text-align: end;
  }

  &__name {
    font-weight: bold;
  }

  &__role {
    font-size: lib.$font-small-size-fluid;
    color: lib.$col-black-60;
  }

  &__img {
    width: lib.$size-64;
    height: lib.$size-64;
    padding: lib.rem(4px);
    border: solid lib.$col-text rem(2px);
    border-radius: 50%;
  }
}
