details {
  border: .1rem solid var(--col-input-border);
  border-radius: .75em;
  margin-bottom: 1rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  background-color: white;

  background-image: url(../Icons/chevron-grey.svg);
  background-position: top 1.3rem right 1.3em;
  background-repeat: no-repeat;
  background-size: .75em auto;

  &[open] {
    background-image: url(../Icons/chevron-grey-turned.svg);
  }

  summary {
    cursor: pointer;
    list-style: none;
    position: relative;
    padding: 1rem;
  }

  summary > * {
    display: inline;
  }

  summary::marker, summary::-webkit-details-marker {
    display: none;
  }

  summary ~ * {
    animation: details-sweep .5s ease-in-out;
  }

  :first-child frame{
    margin-top: 0;
  }

  :last-child > frame {
    margin-bottom: 0!important;
    margin-block-end: 0!important;;
  }

  .textmedia--orientation-above .textmedia__figure,
  .textmedia--orientation-below .textmedia__figure {
    max-width: 100%!important;
    width: auto!important;
    height: auto!important;
    margin-left: 0!important;
    margin-right: 0!important;
    margin-bottom: 0!important;
  }

  .o-accordion__content > div.frame {
    margin: unset!important;
  }

  .textmedia__text {
    width: 100%;
  }
}

@keyframes details-sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}
