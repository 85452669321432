@use '../Abstracts' as lib;

// ==================
// ===== FOOTER =====
// ==================

.footer {
  overflow: hidden;
  width: 100%;

  &__breadcrumb,
  &__menus-wrap,
  &__signature {
    &:before {
      content: '';
      display: block;
      grid-column: max-content-width;
      width: 100%;
      height: lib.rem(1px);
      margin-bottom: lib.$size-32;
      background-color: lib.$col-black-20;
    }
  }

  &__breadcrumb {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &__social {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &__social-list {
    display: flex;
    gap: lib.$size-12;
    justify-content: space-between;
    padding: lib.$size-32 0;
    list-style: none;
  }

  &__social-media-link {
    display: block;
    border-radius: 100%;
    flex: 0 1 64px;

    img {
      display: block;
      width: 100%;
      max-width: lib.$size-64;
      height: 4rem;
    }
  }

  &__menus-wrap {
    @include lib.set-content-width(lib.$max-content-width);

    padding-bottom: lib.$size-32;
  }

  &__menus {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: lib.$size-12;
    align-items: stretch;
    font-size: lib.$font-small-size-fluid;

    @include lib.breakpoint-min(sm) {
      grid-template-columns: repeat(auto-fit, minmax(208px, 1fr));
    }

    @include lib.breakpoint-min(md) {
      grid-template-columns: repeat(auto-fit, minmax(176px, 1fr));
    }
  }

  &__signature {
    @include lib.set-content-width(lib.$max-content-width);

    text-align: center;

    @include lib.breakpoint-min(lg) {
      text-align: left;
    }

    &:before {
      margin-bottom: lib.$size-24;
    }
  }

  &__imprint {
    margin-bottom: lib.$size-32;

    @include lib.breakpoint-min(sm) {
      display: flex;
      gap: lib.$size-32;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__copyright {
    white-space: nowrap;
  }

  &__imprint-nav {
    font-size: lib.$font-small-size-fluid;
    text-align: start;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline;

      &:not(:last-of-type) {
        &:after {
          content: ' · ';
        }
      }
    }

    a, button {
      color: lib.$col-black-80;

      &:hover {
        text-decoration: underline lib.$col-black-80;
      }
    }

    button {
      &:focus-visible {
        outline: .2rem solid currentColor;
        outline-offset: .1rem;
        text-decoration: none;
      }
    }
  }

  &__donation {
    padding-block: lib.$size-32;

    @include lib.breakpoint-min(md) {
      padding-block: lib.$size-64;
    }
  }
}

.footer-acco {
  padding-bottom: lib.$size-12;
  border-bottom: 1px solid lib.$col-black-05;

  @include lib.breakpoint-min(xxl) {
    border-bottom: none;
  }

  &:last-of-type {
    @include lib.breakpoint-max(sm) {
      border-bottom: none;
    }
  }

  &__trigger {
    display: flex;
    gap: lib.$size-24;
    justify-content: space-between;
    width: 100%;
    text-align: left;

    &:focus {
      outline: none;

      .footer-acco__icon {
        border-color: transparent;
        box-shadow: 0 0 0 lib.rem(3px) lib.$col-blue;
      }
    }
  }

  &__title {
    margin-top: lib.rem(6px);
  }

  &__icon {
    position: relative;
    display: block;
    width: lib.$size-32;
    min-width: lib.$size-32;
    height: lib.$size-32;
    border: 1px solid lib.$col-black-20;
    border-radius: 50%;
    transition: transform lib.$time * .5 lib.$timing-function;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: lib.rem(13px);
      height: lib.rem(3px);
      border-radius: lib.rem(2px);
      background-color: lib.$col-dark-blue;
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    .footer-acco__trigger[aria-expanded='true'] & {
      transform: rotate(45deg);
    }

    @include lib.breakpoint-min(sm) {
      display: none;
    }
  }

  &__panel {
    @include lib.breakpoint-min(sm) {
      display: block !important;
    }
  }

  &__menu {
    margin-top: lib.$size-8;
  }

  &__link {
    display: block;
    padding: lib.$size-8 0;
  }
}
