@use '../Abstracts' as lib;

// =========================================
// ===== DONATION CALLTOACTION ELEMENT =====
// =========================================

.donation-calltoaction {
  width: unset;
  max-width: unset;
  padding-block: lib.$size-32;

  @include lib.breakpoint-min(sm) {
    padding-block: lib.$size-64;
  }

  &__body {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &__text {
    margin-block-end: lib.$size-16;
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__currency {
    position: relative;
    right: lib.rem(20px);
    color: lib.$col-blue;

    @include lib.breakpoint-max(sm) {
      right: lib.$size-24;
      grid-row: 1;
      grid-column: 1 / -1;
      justify-self: flex-end;
    }
  }

  .o-media__img {
    margin: 0;

    @include lib.breakpoint-min(sm) {
      max-width: 50%;
      margin-inline: auto;
    }

    img {
      width: 100%;
      margin-bottom: lib.$size-16;
      border-radius: lib.rem(8px);
    }
  }

  .o-media__body {
    text-align: center;
  }

  .o-simple-form__item {
    display: grid;
    grid-template-columns: auto;
    row-gap: lib.$size-16;
    align-items: center;
    justify-items: center;
    width: fit-content;
    margin-inline: auto;

    @include lib.breakpoint-min(sm) {
      display: flex;
      justify-content: center;
      width: unset;
    }

    input {
      max-width: lib.rem(125px);
      text-align: right;
      padding-block: lib.$size-8;
      padding-inline-end: lib.$size-24;

      @include lib.breakpoint-max(sm) {
        grid-row: 1;
        grid-column: 1 / -1;
      }
    }
  }
}
