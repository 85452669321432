@use '../Abstracts' as lib;

// ==========================
// ===== GLOBAL CLASSES =====
// ==========================

@mixin bg--grey {
  --col-background: #{lib.$col-black-80};
  --col-text: #{lib.$col-white};
  --col-text-highlight: #{lib.$col-white};
  --col-text-shaded: #{lib.$col-white-80};
  --col-button-border: #{lib.$col-white};
  --col-button-background: #{lib.$col-white};
  --col-button-text: #{lib.$col-black-80};
  --col-link: #{lib.$col-white};
  --col-link-underline: #{lib.$col-white};
  --col-link-hover: #{lib.$col-white};
  --col-headline: #{lib.$col-white};
  --col-blockquote: #{lib.$col-black-20};
}

.bg--grey {
  @include bg--grey;
}

@mixin bg--lightgrey {
  --col-background: #{lib.$col-black-05};
  --col-text: #{lib.$col-black-80};
  --col-text-highlight: #{lib.$col-red};
  --col-text-shaded: #{lib.$col-black-60};
  --col-button-border: #{lib.$col-blue};
  --col-button-background: #{lib.$col-blue-gradient};
  --col-button-text: #{lib.$col-white};
  --col-link: #{lib.$col-blue};
  --col-link-underline: #{lib.$col-blue};
  --col-link-hover: #{lib.$col-blue};
  --col-headline: #{lib.$col-red};
  --col-blockquote: #{lib.$col-red};
}

.bg--lightgrey {
  @include bg--lightgrey;
}

.bg--red {
  --col-background: #{lib.$col-red-gradient};
  --col-text: #{lib.$col-white};
  --col-text-highlight: #{lib.$col-white};
  --col-text-shaded: #{lib.$col-white-80};
  --col-button-border: #{lib.$col-white};
  --col-button-background: #{lib.$col-white};
  --col-button-text: #{lib.$col-red};
  --col-link: #{lib.$col-white};
  --col-link-underline: #{lib.$col-white};
  --col-link-hover: #{lib.$col-white};
  --col-headline: #{lib.$col-white};
  --col-blockquote: #{lib.$col-black-20};
}

@mixin bg--blue {
  --col-background: #{lib.$col-blue-gradient};
  --col-text: #{lib.$col-white};
  --col-text-highlight: #{lib.$col-white};
  --col-text-shaded: #{lib.$col-white-80};
  --col-button-border: #{lib.$col-white};
  --col-button-background: #{lib.$col-white};
  --col-button-text: #{lib.$col-blue};
  --col-link: #{lib.$col-white};
  --col-link-underline: #{lib.$col-white};
  --col-link-hover: #{lib.$col-white};
  --col-headline: #{lib.$col-white};
  --col-blockquote: #{lib.$col-black-20};
}

.bg--blue {
  @include bg--blue;
}

@mixin bg--default {
  --col-background: #{lib.$col-white};
  --col-text: #{lib.$col-black-80};
  --col-text-highlight: #{lib.$col-red};
  --col-text-shaded: #{lib.$col-black-60};
  --col-button-border: #{lib.$col-blue};
  --col-button-background: #{lib.$col-blue-gradient};
  --col-button-text: #{lib.$col-white};
  --col-link: #{lib.$col-blue};
  --col-link-underline: #{lib.$col-white};
  --col-link-hover: #{lib.$col-blue};
  --col-headline: #{lib.$col-red};
  --col-blockquote: #{lib.$col-red};
}

.bg--default {
  @include bg--default;
}

@mixin bg {
  background: lib.$col-background;
  color: lib.$col-text;
  border-color: lib.$col-button-border;
}

.bg {
  @include bg;
}

// No Selection
.no-select {
  user-select: none;
  cursor: default;
}

// Text Modifications
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }

// hidden
.h-hide, .hidden {
  display: none !important;
}

// Scroll anchors header height offset
.scroll-anchor {
  scroll-margin-top: lib.$toolbar-height * 2;
}
