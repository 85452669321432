@use '../Abstracts' as lib;

// ============================
// ===== FE LOGIN ELEMENT =====
// ============================

.frame-type-felogin_login {
  fieldset {
    div {
      input[type='submit'] {
        width: fit-content;
        padding: .5em 1em .7em;
        border-radius: lib.rem(48px);
        background: lib.$col-button-background;
        color: lib.$col-button-text;
        transition: all lib.$time lib.$timing-function;

        &:hover, &:focus {
          background: lib.$col-button-background;
        }

        &:hover {
          transform: scale(1.05);
        }

        &:focus {
          box-shadow: 0 0 0 lib.rem(2px) lib.$col-button-text, 0 0 0 lib.rem(4px) lib.$col-button-border;
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-block-end: lib.$size-16;

        input {
          padding-block: lib.$size-12;
        }
      }
    }
  }
}
