@use '../Abstracts' as lib;

// =====================
// ===== Link List =====
// =====================

.link-list {
  &__item {
    display: block;

    &:not(:last-of-type) {
      border-bottom: 1px solid lib.$col-black-20;
    }
  }

  &__link {
    display: block;
    width: 100%;
    padding: lib.$size-16 0;
    line-height: 1.2em;
    text-decoration: none;
    text-align: start;

    &:hover {
      text-decoration: none;

      .link-list__label svg {
        transform: translateX(.4rem) rotate(-90deg);
      }
    }
  }

  &__label {
    display: flex;
    justify-content: space-between;
    gap: lib.$size-32;

    svg {
      width: lib.$size-16;
      min-width: lib.$size-16;
      height: lib.$size-16;
      margin-top: lib.$size-8 * .5;
      transform: rotate(-90deg);
      transform-origin: center;
      transition: transform lib.$time * .5 lib.$timing-function;
    }
  }
}
