@use '../Abstracts' as lib;

// ============================================
// ===== Default FSC Fluid-Styled-Content =====
// ============================================

main > :where(.frame) {
  scroll-margin-top: 20vh;
}

.frame {
  margin-block: lib.$size-32;

  @include lib.breakpoint-min(sm) {
    margin-block: lib.$size-64;
  }

  &:last-child {
    margin-block-end: lib.$size-48;

    @include lib.breakpoint-min(sm) {
      margin-block-end: lib.$size-64;
    }
  }

  &.bg--grey,
  &.bg--lightgrey {
    padding-block: lib.$size-32;
    margin-block: 0;

    @include lib.breakpoint-min(sm) {
      padding-block: lib.$size-64;
    }
  }

  &.bg--lightgrey {
    + .bg--lightgrey {
      padding-block-start: 0;
    }

    &:last-child {
      margin-block-end: lib.$size-16;
    }
  }

  &.bg--grey {
    + .bg--grey {
      padding-block-start: 0;
    }

    &:last-child {
      margin-block-end: lib.$size-16;
    }
  }

  &.bg--red,
  &.bg--blue {
    padding-block: lib.$size-32;
    margin-block: 0;

    &:last-child {
      margin-block-end: lib.$size-16;
    }

    @include lib.breakpoint-min(sm) {
      padding-block: lib.$size-64;
    }
  }

  &.frame-type-list {
    width: unset;
    max-width: unset;
  }

  &.frame-list-type-news_pi1 {
    width: unset;
    max-width: unset;
  }

  &.frame-type-news_newsliststicky,
  &.frame-type-news_newsdetail {
    width: unset;
    max-width: unset;
  }

  &.frame-list-type-drkdonations_donation,
  &.frame-list-type-drkdonations_barometer,
  &.frame-list-type-drkdonations_shop {
    margin-block: 0;

    &:last-child {
      margin-block-end: lib.$size-16;
    }
  }

  &.frame-list-type-powermail_pi1,
  &.frame-list-type-drkvorort_organisationplugin:not(.gridelements__wrapper &),
  &.frame-list-type-solr_pi_results {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &.frame-list-type-drkaddresses_organisationplugin:not(.gridelements__wrapper &) {
    @include lib.set-content-width(lib.$med-content-width);
  }
}

.frame-type-news_newsliststicky, .frame-type-news_pi1 {
  width: unset;
  max-width: unset;
}

// keep styling of nested content elements
.frame-type-drkdonation_showcontent {
  display: contents;
}
