@use 'sass:color';
@use '../Abstracts' as lib;

// ====================
// ===== Sub Menu =====
// ====================

.sub-menu {
  &__bar {
    position: fixed;
    top: lib.$toolbar-height;
    left: 0;
    z-index: lib.$z-index-sub-menu-bar;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: lib.$submenubar-height;
    border-bottom: 1px solid lib.$col-black-20;
    background-color: lib.$col-white;
    transition: top lib.$time lib.$timing-function;

    // Prevent gap between sub-menu and toolbar during scrolling animation
    box-shadow: 0 lib.$size-8 * -1 0 0 lib.$col-white;

    .hidden-toolbar & {
      top: 0;
    }
  }

  &__topic-button {
    display: block;
    width: 100%;
    height: 100%;
    padding: lib.$size-8;

    svg {
      width: lib.$size-16;
      min-width: lib.$size-16;
      height: lib.$size-16;
      margin-top: lib.$size-8;
      margin-right: lib.$size-8 * .66;
      transition: transform lib.$time lib.$timing-function;
      transform-origin: center;
    }

    &--open {
      svg {
        transform: scale(-1);
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      > span {
        display: inline-block;
        padding-inline: 2px;
        outline: .2rem solid lib.$col-blue;
        outline-offset: 0;
        text-decoration: none;
      }
    }
  }

  &__nav-wrap {
    position: fixed;
    top: lib.$toolbar-height + lib.$submenubar-height;
    bottom: 0;
    left: 0;
    z-index: lib.$z-index-sub-menu;
    width: 100%;
    transition: transform lib.$time lib.$timing-function;
    transform: translateY(calc(-100% - #{lib.$size-32}));

    &--open {
      transform: translateY(0);

      @include lib.breakpoint-min(md) {
        transform: translateY(#{lib.$size-16});
      }
    }

    .hidden-toolbar & {
      top: lib.$submenubar-height;
    }
  }

  &__nav {
    width: 100%;
    height: 100%;
    padding-block: lib.$size-12;
    background-color: lib.$col-white;

    @include lib.breakpoint-min(md) {
      max-width: lib.rem(380px);
      height: auto;
      margin: 0 auto;
      border: 1px solid lib.$col-black-20;
      border-radius: lib.$size-16;
      box-shadow: 0 lib.$size-8 lib.$size-16 0 color.adjust(black, $alpha: -.8);
    }
  }

  &__list-wrap {
    overflow: auto;
    max-height: 100%;
    padding: lib.$size-8 lib.$size-32;

    @include lib.breakpoint-min(md) {
      max-height: 60vh;
      padding-inline: lib.$size-32;
    }
  }

  &__list {
    // position: absolute;
    top: lib.$size-32;
    right: lib.$size-32;
    left: lib.$size-32;

    .sub-menu__list {
      top: 0;
      right: 0;
      left: 0;
    }
  }

  &__item {
    &.visible {
      display: block;
    }

    &.visible:not(:last-of-type) {
      border-bottom: 1px solid lib.$col-black-20;
    }
  }

  &__button,
  &__link {
    display: block;
    width: 100%;
    padding: lib.$size-16 2px;
    color: lib.$col-text;
    font-weight: bold;
    line-height: 1.2em;
    text-align: left;

    &:hover {
      text-decoration: unset;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: .2rem solid lib.$col-blue;
      outline-offset: 0;
      text-decoration: none;
    }

    &--current,
    &--active {
      color: lib.$col-blue;
    }
  }

  &__link:hover {
    .sub-menu__link-label {
      text-decoration: underline lib.$col-link-hover;
    }
  }

  &__overview-label {
    display: block;
    color: lib.$col-black-40;
    font-weight: normal;
    font-size: lib.$font-small-size-fluid;
    line-height: 1;
  }

  &__button {
    display: flex;
    gap: lib.$size-8;
    justify-content: space-between;

    svg {
      width: lib.$size-16;
      min-width: lib.$size-16;
      height: lib.$size-16;
      margin-top: lib.$size-8 * .5;
      transition: transform lib.$time * .5 lib.$timing-function;
      transform: rotate(-90deg);
      transform-origin: center;
    }

    &:hover {
      text-decoration: none;

      svg {
        transform: rotate(-90deg) translateY(.4rem);
      }
    }

    &--back {
      justify-content: flex-start;
      color: lib.$col-blue;

      svg {
        transform: rotate(90deg);
      }

      &:hover {
        svg {
          transform: rotate(90deg) translateY(.4rem);
        }
      }
    }
  }
}
