@use '../Abstracts' as lib;

// ========================
// ===== Donation CTA =====
// ========================

.donation-cta {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: lib.$z-index-donation-cta;
  display: flex;
  gap: lib.$size-12;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: lib.$size-12 lib.$size-32;
  font-size: lib.$font-small-size-fluid;
  transition-property: transform, box-shadow;
  transition-duration: lib.$time;
  transition-timing-function: lib.$timing-function;

  @media (min-width: 380px) {
    gap: lib.$size-24;
  }

  @include lib.breakpoint-min(sm) {
    max-width: lib.$min-content-width * .5;
    margin-inline-end: lib.$size-32;
    margin-block-end: lib.$size-32;
    border-radius: lib.rem(6px);
    box-shadow: 0 lib.rem(7px) lib.$size-32 rgba(0 0 0 / 20%);
    flex-direction: column;
    text-align: center;
    padding-block: lib.$size-32 lib.$size-16;
  }

  &--hidden {
    box-shadow: 0 0 0 rgba(255 255 255 / 0%);
    transform: translateY(100%);

    @include lib.breakpoint-min(sm) {
      transform: translateX(calc(100% + #{lib.$size-32}));
    }
  }

  &__button {
    padding: .5em 1em;
    white-space: nowrap;
  }
}
