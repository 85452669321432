@use '../Abstracts' as lib;

// ==================================
// ===== Search Results Address =====
// ==================================

.search-result-address {
  &__list {
    margin-block-start: lib.$size-16;
  }

  &__item {
    margin-block-end: lib.$size-24;
  }

  &__results {
    div {
      margin-block-end: lib.$size-12;
    }
  }

  &__mapid {
    display: block;
    margin-block-end: lib.$size-8;
  }
}
