@use 'sass:color';
@use '../Abstracts' as lib;

// ==================
// ===== HEADER =====
// ==================

.header {
  text-align: center;

  @include lib.set-content-width(lib.$min-content-width);

  &__header {
    &--withsubheader {
      margin-block-end: lib.$size-16;
    }
  }

  &__subheader {
    display: block;
    margin-block-end: lib.$size-16;
    line-height: 1.2;

    @include lib.breakpoint-min(sm) {
      margin-block-end: lib.$size-32;
    }
  }

  &__preheader {
    margin-block-end: 0;
  }
}
