@use '../Abstracts' as lib;

// ===================================
// ===== DONATION BUTTON ELEMENT =====
// ===================================

.donation-button {
  .text {
    margin-bottom: lib.$size-32;
    text-align: center;
  }

  &__quick, &__headline {
    @include lib.set-content-width(lib.$min-content-width);
  }

  &__headline {
    margin-bottom: lib.$size-16;
    text-align: center;

    h2 {
      margin-bottom: lib.$size-8;
    }
  }

  &__controller-headline {
    text-align: center;

    h3 {
      margin-bottom: lib.$size-8;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__values {
    width: 100%;
    margin-bottom: lib.$size-24;
  }

  &__range {
    overflow: hidden;
    height: 48px !important;
    border: none !important;
    background: transparent !important;

    .noUi-handle.noUi-handle-lower {
      top: 50%;
      width: 32px;
      height: 32px;
      border: none;
      border-radius: 50%;
      box-shadow: 1px 2px 6px 0 lib.$col-black-60;
      cursor: pointer;
      transform: translateY(-50%);

      &:after {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: lib.$col-red;
        transform: translate(-50%, -50%);
      }

      span {
        display: none;
      }
    }

    .noUi-base {
      left: 17px;
      width: calc(100% - 33px);
    }

    .noUi-origin {
      top: 50%;
      height: 3px;
      border-radius: 3px;
      background-color: lib.$col-black-20;
      transform: translateY(-50%);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100vw;
        height: 6px;
        border-radius: 6px;
        background-color: lib.$col-white;
        transform: translate(-100%, -50%);
      }
    }
  }

  &__goals {
    // not displayed until design is decided
    display: none;

    // flex-direction: column;
    // align-items: center;
  }

  &__goal-sum,
  &__goal-text {
    padding-left: 0 !important;
    color: lib.$col-white !important;
    text-align: center;
  }

  &__goal-sum {
    margin-block: lib.$size-8 !important;
  }

  &__button {
    &--range {
      align-self: center;
      width: fit-content;
    }

    &--mobile {
      display: none;
    }
  }
}
