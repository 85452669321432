@use '../Abstracts' as lib;

// =======================
// ===== MAP ELEMENT =====
// =======================

.tx-geofacts {
  padding: lib.$size-32;
  overflow: hidden;
}

.geomaps {
  margin: 0 auto !important;

  &__image, &__map {
    &:before, &:after {
      display: none !important;
    }
  }

  &__image {
    margin: 0 auto !important;
  }

  &__title {
    h2 {
      color: lib.$col-white !important;
      font-size: lib.$font-h2-size-fluid !important;
    }
  }

  &__popup {
    color: lib.$col-black-80;
    padding: 0 !important;

    a {
      color: lib.$col-blue;
      text-decoration: none;
    }

    figcaption {
      color: lib.$col-black-80 !important;
    }
  }

  &__popup-block {
    padding: lib.$size-32 !important;
  }

  &__description, &__popup-title {
    color: lib.$col-black-80;
  }

  .popuptip {
    padding: lib.$size-8;

    p {
      font-size: lib.$font-small-size-fluid;
      line-height: lib.$font-main-line-height;
    }

    &.is-active {
      color: lib.$col-black-80 !important;
    }
  }

  &__legend {
    margin: 0 lib.$size-16;
  }
}
