@use '../Abstracts/index' as lib;

// =========================================
// ===== Addresses Organisation Plugin =====
// =========================================

.c-addresses {
  &--general {
    li {
      margin-bottom: lib.$size-8;
    }
  }

  &--listingcontainer,
  &--generalcontainer {
    display: flex;

    .o-gridblock__item {
      padding-right: lib.$size-16;
      flex: 1;

      &:last-child {
        padding-left: lib.$size-16;
        padding-right: 0;
      }
    }
  }

  &--generalcontainer {
    @include lib.breakpoint-max(md) {
      flex-direction: column;

      img {
        width: 100%;
      }
    }

    .o-gridblock__item {
      &:last-child {
        @include lib.breakpoint-max(md) {
          padding-left: 0;
        }
      }
    }
  }

  &--listingcontainer {
    flex-wrap: wrap;
    margin-top: lib.$size-64;

    @include lib.breakpoint-max(sm) {
      flex-direction: column;
    }

    > :first-child {
      width: 100%;
      margin-bottom: lib.$size-16;
    }

    .o-gridblock__item {
      &:last-child {
        @include lib.breakpoint-max(sm) {
          padding-left: 0;
        }
      }
    }
  }

  &--listinggroup {
    margin-top: lib.$size-16;
    margin-bottom: lib.$size-16;
  }

  &--listinggroup,
  &--general {
    li {
      list-style-type: none;
      padding-left: lib.$size-32;

      svg {
        width: lib.rem(20px);
        height: lib.rem(20px);
        margin-top: - lib.$size-8;
        margin-right: lib.$size-8;
        margin-left: - lib.$size-32;
        vertical-align: middle;
        stroke: lib.$col-dark-blue;
      }
    }
  }
}
