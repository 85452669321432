// ===========================
// ===== drk_contactform =====
// ===========================

@use '../Abstracts' as lib;
@import '../Atoms/utility-classes';

.frame-type-drkcontactform_contactform {

  @include bg;
  @include bg--lightgrey;

  width: unset;
  max-width: unset;

  padding-top: lib.$size-32;
  padding-bottom: lib.$size-32;

}
