@use 'sass:color';
@use '../Abstracts' as lib;

// =========================
// ====== Ke Search ======
// =========================

.c-paginationcontainer {
  text-align: center;
}

