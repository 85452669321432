@use '../Abstracts' as lib;

.frame-type-drk_template_stage {
  max-width: unset;
  width: auto;
}

.stage-image {
  @include lib.breakpoint-min(sm) {
    @include lib.set-content-width(lib.$max-content-width);
  }

  img {
    width: 100%;
  }
}
