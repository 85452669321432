@use 'sass:math';
@use 'sass:string';
@use '../Abstracts' as lib;

// ======================
// ===== TYPOGRAPHY =====
// ======================

p {
  margin-block-end: 1em;
}

.lead {
  font-weight: bold;
  font-size: lib.$font-lead-size-fluid;
}

// fine print
small,
.small {
  font-size: lib.$font-small-size-fluid;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a,
.link-style {
  background-color: transparent;
  color: lib.$col-link;
  text-decoration: underline lib.$col-link-underline;

  &:hover {
    text-decoration: underline lib.$col-link-hover;
  }

  &:focus-visible {
    outline: .2rem solid currentColor;
    outline-offset: .1rem;
    text-decoration: none;
  }
}

code, kbd, pre, samp {
  display: inline;
  padding: .1em .3em;
  border-radius: .4rem;
  background-color: lib.$col-black-20;
  color: lib.$col-black-80;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

u {
  padding: 0 .3125em;
}

blockquote {
  margin: 0;
  margin-block-end: 1em;
  padding: lib.$size-12 0;
  border-top: solid lib.$col-black-20 1px;
  border-bottom: solid lib.$col-black-20 1px;
  font-size: lib.$font-blockquote-size-fluid;
  font-weight: bold;

  p {
    display: inline;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: .9em;
      height: .9em;
      margin-inline-end: .2em;
      background-image: url('data:image/svg+xml;utf-8,<svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><g id="c"><rect id="d" width="16" height="16" style="fill:none;"/><path id="e" d="M0,0V6.94H2.97c.03,.96-.19,1.92-.63,2.77-.53,.85-1.37,1.47-2.34,1.73v2.97c.91-.11,1.79-.4,2.59-.83,.78-.42,1.48-.99,2.05-1.67,.57-.68,1.02-1.46,1.33-2.3,.32-.87,.46-1.8,.43-2.73V0H0ZM9.6,0V6.94h2.97c.03,.96-.19,1.92-.63,2.77-.53,.85-1.37,1.47-2.34,1.73v2.97c.91-.11,1.79-.4,2.59-.83,.78-.42,1.48-.99,2.05-1.67,.57-.68,1.02-1.46,1.33-2.3,.32-.87,.46-1.8,.43-2.73V0h-6.4Z" style="fill:#e60005;"/></g></svg>');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:after {
      content: '\00a0';
      display: inline;
      vertical-align: middle;
      margin-inline-start: .1em;
      padding-inline-start: 1em;
      background-image: url('data:image/svg+xml;utf-8,<svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><g id="c"><rect id="d" width="16" height="16" style="fill:none;"/><path id="e" d="M0,14.42V7.48H2.97c.03-.96-.19-1.92-.63-2.77-.53-.85-1.37-1.47-2.34-1.73V0C.91,.11,1.79,.4,2.59,.83c.78,.42,1.48,.99,2.05,1.67,.57,.68,1.02,1.46,1.33,2.3,.32,.87,.46,1.8,.43,2.73v6.89H0Zm9.6,0V7.48h2.97c.03-.96-.19-1.92-.63-2.77-.53-.85-1.37-1.47-2.34-1.73V0c.91,.11,1.79,.4,2.59,.83,.78,.42,1.48,.99,2.05,1.67,.57,.68,1.02,1.46,1.33,2.3,.32,.87,.46,1.8,.43,2.73v6.89h-6.4Z" style="fill:#e60005;"/></g></svg>');
      background-position: center .03em;
      background-size: .9em .9em;
      background-repeat: no-repeat;
      line-height: 0;
    }
  }
}

hr {
  height: 1px;
  margin: 1.5em 0;
  border: none;
  background: lib.$col-black-20;
}

// ====================
// ===== Headings =====
// ====================

// global for all headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1em;
  font-family: lib.$font-main, sans-serif;
  line-height: lib.$font-headings-line-height;
  font-weight: bold;
  hyphens: auto;
}

h1, .h1 {
  margin-bottom: .8em;
  color: lib.$col-headline;
  font-size: lib.$font-h1-size-fluid;
}

h2, .h2 {
  font-size: lib.$font-h2-size-fluid;
}

h3, .h3 {
  font-size: lib.$font-h3-size-fluid;
  line-height: calc(#{lib.$font-headings-line-height} * 1.1);
}

h4, .h4 {
  font-size: lib.$font-h4-size-fluid;
  line-height: calc(#{lib.$font-headings-line-height} * 1.1);
}

// Margin-top for headings after a typography block
:where(address, blockquote, dl, figure, form, ol, p, pre, table, ul) {
  ~ :is(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6) {
    margin-top: 1em;
  }
}

:where(.frame-type-header) {
  :is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: 0;
  }
}
