@use '../Abstracts' as lib;
@use 'sass:color';

// ===================================
// ===== Remove Browser defaults =====
// ===================================

// content width for all forms with o-form class from legacy content

.o-form {
  @include lib.set-content-width(lib.$min-content-width);

  .gridelements__wrapper & {
    width: unset;
    max-width: unset;
  }
}

input, select, textarea, fieldset, legend {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  outline: 0;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

// clears the 'X' from Internet Explorer
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

// clears the 'X' from Chrome
input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}


// ==============================
// ===== Global form styles =====
// ==============================

fieldset {
  margin-bottom: lib.$size-32;

  legend {
    margin-bottom: .5em;
    font-size: 1.3em;
    font-family: lib.$font-main;
    font-weight: bold;
  }
}

label {
  display: block;
  margin-bottom: .5rem;
  color: lib.$col-input-label;
  font-size: lib.$font-small-size-fluid;
}

.help-block {
  display: block;
  padding: .1em 1.2em;
  color: lib.$col-text;
  font-size: .8em;

  &.error {
    color: lib.$col-red;
  }
}

.form-group,
.o-select--reloaded,
.o-form__item {
  position: relative;
  margin-bottom: lib.$size-16;
}

label.control-label,
label.o-form__label {
  position: absolute;
  top: .5em;
  left: 1.3em;
  font-weight: bold;
  font-size: .7em;

  &.form-group-label {
    top: .5em;
    left: 1.175em;
  }
}


// --- form font settings ---

input,
.input:before,
select,
textarea,
.custom-file-input,
.custom-file-label,
.custom-file-label:after {
  // minimum for forms should be 16px to prevent zooming on mobile
  padding: 1.35em .5em .65em .8em;
  border-radius: .75em;
  font-size: inherit;
  font-family: lib.$font-main;
  line-height: 1.4em;
}


// --- global inputs ---

input,
select,
textarea, {
  display: block;
  width: 100%;
  border: .1rem solid lib.$col-input-border;
  border-radius: .75em;
  background-color: lib.$col-white;
  color: lib.$col-blue;
  font-weight: bold;
  font-size: lib.$font-main-size-fluid;
  transition:
    border lib.$time ease-in-out,
    box-shadow lib.$time ease-in-out;

  &::-webkit-input-placeholder {
    color: lib.$col-input-placeholder;
  }

  &:-moz-placeholder {
    color: lib.$col-input-placeholder;
  }

  &::-moz-placeholder {
    color: lib.$col-input-placeholder;
  }

  &:-ms-input-placeholder {
    color: lib.$col-input-placeholder;
  }

  &:focus {
    border-color: lib.$col-blue;
    box-shadow: 0 0 0 .175rem lib.$col-blue;

    &::-webkit-input-placeholder {
      color: lib.$col-black-60;
    }

    &:-moz-placeholder {
      color: lib.$col-black-60;
    }

    &::-moz-placeholder {
      color: lib.$col-input-placeholder;
    }

    &::-ms-input-placeholder {
      color: lib.$col-input-placeholder;
    }
  }

  &.filled,
  &.filled + .custom-file-label {
    border-color: lib.$col-button-border;
  }

  &.error,
  &.filled--focusout:invalid {
    border-color: lib.$col-red;

    &:focus, &:hover, &:active {
      border-color: lib.$col-red;
      box-shadow: 0 0 0 .1rem lib.$col-red;
    }
  }

  &:disabled {
    background: lib.$col-text-shaded !important;
    opacity: .5;
    cursor: not-allowed !important;

    &::placeholder {
      color: lib.$col-text-shaded !important;
    }
  }
}

// --- parsley ---
.parsley-required,
.parsley-type,
.parsley-custom-error-message,
.parsley-pattern,
.parsley-range
{
  color: lib.$col-dark-red;
}


// --- text area ---

textarea {
  overflow: auto;
  min-height: calc(1em * 10);
  line-height: lib.$font-main-line-height;
  resize: vertical;
}


// --- checkbox and radio ---

.form-check-label,
.radio > label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  cursor: pointer;
}

input[type='checkbox'],
input[type='radio'] {
  position: relative;
  top: -.25em;
  display: inline-block;
  vertical-align: middle;
  width: 1.75rem;
  min-width: 1.75rem;
  height: 1.75rem;
  margin-right: .75em;
  padding: 0;
  border: .1rem solid lib.$col-radio-button-border;
  border-radius: .3rem;
  background-color: lib.$col-white;
  box-shadow: none;
  cursor: pointer;
  transition: background-color lib.$time * .5 ease-in-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: no-repeat center;
    background-size: 50% 50%;
    opacity: 0;
    transition: opacity lib.$time * .5 ease;
  }

  &:hover {
    background-color: lib.$col-radio-button;
    box-shadow: inset  0 0 0 .2rem lib.$col-white;
  }

  &:focus {
    border-color: lib.$col-blue;
    box-shadow: inset  0 0 0 .2rem lib.$col-white, 0 0 0 .2rem lib.$col-blue;
  }

  &:checked {
    border: .1rem solid lib.$col-radio-button-border;
    background-color: lib.$col-radio-button;
    box-shadow: inset  0 0 0 .2rem lib.$col-white;
  }

  &:disabled {
    + span {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

input[type='checkbox'] {
  &:before {
    opacity: 1;
  }

  &:focus {
    border-color: lib.$col-blue;
    box-shadow: inset  0 0 0 .2rem lib.$col-white, 0 0 0 .2rem lib.$col-blue;
  }

  &:checked {
    &:before {
      background-image: url('../Icons/check-white.svg');
      opacity: 1;
    }
  }
}

input[type='radio'] {
  border-radius: 100%;

  &:before {
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: lib.$col-white;
    transform: translate(-50%, -50%);
  }

  &:focus {
    border-color: lib.$col-blue;
    box-shadow: inset  0 0 0 .2rem lib.$col-white, 0 0 0 .2rem lib.$col-blue;
  }
}

label > input[type='radio']:checked + *,
label > input[type='checkbox']:checked + * {
  border-color: lib.$col-blue;
  color: lib.$col-blue;
  box-shadow: inset  0 0 0 .2rem lib.$col-white;
}

input[type="radio"].button-check {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}

.button-check:checked + .button {
  box-shadow: 0 0 0 .125rem var(--col-button-text),0 0 0 .25rem var(--col-button-border);
}

// --- selects ---

select:not([multiple]),
select[size='1'] {
  min-height: 3.5em;
  padding-top: 1.25em;
  padding-right: 2.5em;
  padding-bottom: .25em;
  background-image: url('../Icons/chevron-grey.svg');
  background-position: center right .5em;
  background-size: .75em auto;
  background-repeat: no-repeat;
  color: lib.$col-blue;
  cursor: pointer;

  @include lib.breakpoint-min(sm) {
    background-position: center right 1.35em;
  }

  &:focus {
    background-image: url('../Icons/chevron-blue.svg');
    color: lib.$col-input-placeholder;
  }
}


// --- custom uploads ---

.custom-file {
  position: relative;
  cursor: pointer;
}

.custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;

  &::-webkit-file-upload-button {
    display: none;
    visibility: hidden;
  }

  &:focus {
    + .custom-file-label {
      border-color: lib.$col-blue;
      box-shadow: 0 0 0 .2rem lib.$col-blue;
    }
  }
}

.custom-file-label {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 0;
  color: lib.$col-black-80;
  border: .1rem solid lib.$col-input-border;
  font-size: lib.$font-small-size-fluid;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: 'Browse';
    position: absolute;
    top: -.5em;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: auto;
    padding: 1.5em .5em 1em .8em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: lib.$col-black-05;
    transition: border lib.$time ease;
  }

  &:lang(de):after {
    content: 'Datei auswählen';
    color: lib.$col-blue;
  }
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: 'Browse';
}

// --- form plugin ---

.frame-type-form_formframework {
  [class*='hidden'] {
    display: none !important;
  }

  .frame {
    margin: 0 !important;
    padding: 0 !important;

    .textmedia--text-only {
      margin: 0;
      max-width: unset;
      width: 100%;
    }
  }
}

.form-group-radio, .form-group-checkbox {
  padding: .8em .5em .2em .8em;
  border: .1rem solid lib.$col-radio-button-border;
  border-radius: .75em;

  &:focus-within {
    border-color: lib.$col-blue;
    box-shadow: 0 0 0 .175rem lib.$col-blue;
  }

  .inputs-list > .form-group {
    margin-bottom: 0;
  }

  .form-check, .radio {
    padding-top: 1em;
  }
}

.form-group-checkbox {
  background-color: lib.$col-white;
}
.add-on {
  padding: 1em .8em 1em 1.2em;
  border: .1rem solid lib.$col-radio-button-border;
  border-radius: .75em;

  &:focus-within {
    border-color: lib.$col-blue;
    box-shadow: 0 0 0 .175rem lib.$col-blue;
  }

  input[type='checkbox'] {
    top: 0;
  }
}

.form-check, .radio {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -.8em;
    display: block;
    width: calc(100% + 1.3em);
    border-bottom: .1rem solid lib.$col-radio-button-border;
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
}


