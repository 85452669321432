@use 'sass:color';
@use '../Abstracts' as lib;

// =========================
// ===== Global Search =====
// =========================

.global-search {
  &__fieldset {
    position: relative;
    margin-inline: lib.$size-16;
    margin-block-end: 0;
    border-radius: lib.rem(999px);
    box-shadow: 0 lib.$size-8 lib.$size-16 0 color.adjust(black, $alpha: -.8);
  }

  &__input {
    padding: lib.$size-16 lib.$size-16 lib.$size-16 lib.rem(60px);
    border: none;
    border-radius: lib.rem(999px);
    color: lib.$col-blue;
    caret-color: lib.$col-blue;

    &:focus {
      box-shadow: inset 0 0 0 lib.rem(3px) lib.$col-blue;
    }
  }

  &__submit {
    position: absolute;
    top: 50%;
    left: lib.$size-16;
    display: flex;
    justify-content: center;
    align-items: center;
    width: lib.rem(40px);
    height: lib.rem(40px);
    padding: 0;
    border: none;
    border-radius: 50%;
    transform: translateY(-50%);

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 lib.rem(3px) lib.$col-blue;
    }

    svg {
      display: block;
      width: 60%;
      height: 60%;
      color: lib.$col-black-60;
    }
  }

  &__close {
    display: none;
  }
}

@include lib.breakpoint-min(lg) {
  .global-search {
    position: fixed;
    top: lib.$size-12;
    left: 50%;
    z-index: lib.$z-index-global-search;
    width: 100%;
    max-width: lib.rem(360px);
    padding-block-end: lib.$size-24;
    border-radius: lib.$size-32;
    background-color: lib.$col-white;
    box-shadow: 0 lib.$size-8 lib.$size-16 0 color.adjust(black, $alpha: -.8);
    transform: translateX(-50%);

    &__fieldset {
      margin: 0;
      margin-block-end: lib.$size-16;
      border-radius: lib.rem(999px);
      box-shadow: none;
    }

    &__input {
      box-shadow: inset 0 0 0 lib.rem(3px) lib.$col-blue;
      font-size: lib.$font-small-size-fluid;
    }

    &__close {
      position: absolute;
      top: 50%;
      right: lib.$size-12;
      display: block;
      width: lib.$size-24;
      height: lib.$size-24;
      border-radius: 100%;
      background-color: lib.$col-black-60;
      transform: translateY(-50%) rotate(45deg);

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 40%;
        height: 2px;
        border-radius: 2px;
        background-color: lib.$col-white;
        transform: translate(-50%, -50%);
      }

      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      &:focus {
        box-shadow: 0 0 0 lib.rem(2px) lib.$col-white, 0 0 0 lib.rem(5px) lib.$col-blue;
        outline: none;
      }
    }
  }
}
