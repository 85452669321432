@use '../Abstracts' as lib;

// ==============================
// ===== FIXED ELEMENTS EXT =====
// ==============================

.fixedelements {
  @include lib.breakpoint-min(md) {
    background-image: url('/fileadmin/user_upload/Buehnenbilder/Startseite/buehnenbild_schulen_wir_sagen_danke-v3.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-block: lib.$size-32;
  }

  img {
    height: fit-content;
  }

  &__image {
    @include lib.breakpoint-min(md) {
      display: none;
    }
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    gap: lib.$size-32;
  }

  &__content {
    padding: lib.$size-32 0;

    @include lib.breakpoint-min(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: lib.$size-32;
      padding: lib.$size-32;
    }

    @include lib.set-content-width(lib.$med-content-width);
  }

  &__left {
    @include lib.breakpoint-min(md) {
      grid-column: 1 / 2;
    }
  }

  &__right {
    @include lib.breakpoint-min(md) {
      grid-column: 2 / 3;
    }

    figure {
      margin: lib.$size-32 0;
    }
  }

  &__link {
    @include lib.breakpoint-min(md) {
      grid-row: 2;
      grid-column: span 2;
    }
  }

  // threerow
  &__item {
    padding-block: lib.$size-32;

    @include lib.set-content-width(lib.$min-content-width);
  }
}
