@use 'sass:math';
@use 'sass:string';
@use '../../../../../../node_modules/swiper/swiper' as swiper;
@use '../../../../../../node_modules/swiper/modules/a11y/a11y' as swiper-a11y;
@use '../Abstracts' as lib;

// ==================
// ===== TEASER =====
// ==================
.frame-type-menu_sitemap_pages,
.frame-type-menu_subpages,
.frame-type-menu_categorized_pages,
.frame-type-plusdrkcontentelements_menu_timeline {
  max-width: unset;
  width: auto;

  header {
    @include lib.set-content-width(lib.$min-content-width);
  }
}

.teaser__slider {
  @include lib.breakpoint-min(sm) { display: none; }
}

.teaser__grid {
  display: none;

  @include lib.set-content-width(lib.$max-content-width);

  @include lib.breakpoint-min(sm) { display: block; }
}

.teaser__wrapper {
  .teaser__grid .teaser__block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: lib.$size-32;

    @media screen and (min-width: 1120px) { grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }
  }

  .teaser__list {
    @include lib.set-content-width(lib.$med-content-width);
  }
}

.teaser__item:first-child:nth-last-child(1) {
  /* -or- li:only-child { */
  width: max(#{lib.$min-content-width}, 50%);
  max-width: 100%;
  justify-self: center;
}

.teaser__slider .teaser__item {
  width: 100%;
  margin-top: lib.$size-32;
  &:first-child { margin-top: 0; }
}

.teaser__media {
  overflow: hidden;
  margin-block-end: 1rem;
  border-radius: lib.$size-16 * 1.5;
  box-shadow: 0 .5rem 1rem 0 rgb(0 0 0 / 30%);

  figure { margin: 0; }

  img {
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    object-position: center;
  }

  &:focus-within {
    box-shadow: 0 0 0 3px lib.$col-link;
  }

  a { display: block; }
}

.teaser__title {
  font-size: lib.rem(21px);
  font-weight: bold;
  margin-bottom: lib.$size-8;

  @include lib.breakpoint-min(md) { font-size: lib.$font-lead-size-fluid; }
}

.teaser__item .teaser__title a {
  color: lib.$col-link;
  text-decoration: none;
  transition: color .4s;
}

.teaser__item:hover .teaser__title a {
  color: lib.$col-link;
  text-decoration: none;
}

.teaser__content {
  p { margin-bottom: 0; }
}

// Slider
.swiper__teaser {
  width: 100%;
  .swiper-wrapper { height: auto; }
  .swiper-slide { width: 70%; }
}

// List-View
.teaser__list .teaser__item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: lib.$size-32;
  padding-bottom: lib.$size-32;
  border-bottom: 1px solid lib.$col-black-20;

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }

  .teaser__media {
    width: 40%;
    margin-bottom: 0;
  }

  .teaser__media img {
    border-radius: lib.rem(12px);

    @include lib.breakpoint-min(sm) { border-radius: lib.rem(24px); }
  }

  .teaser__content {
    width: calc(100% - 40% - 1rem);
    margin-left: lib.$size-32;

    p, .teaser__text {
      display: none;

      @include lib.breakpoint-min(md) { display: block; }
    }
  }

  .teaser__title {
    font-size: lib.rem(18px);

    @include lib.breakpoint-min(sm) { font-size: lib.$font-h4-size-fluid; }

    @include lib.breakpoint-min(md) { font-size: lib.$font-h3-size-fluid; }
  }
}

.teaser__timeline {
  position: relative;
  padding: 0 0 50px;
  &:before {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    top: 0;
    left: 20px;
    border-radius: 2px;
    background: lib.$col-black-40;
    z-index: 1;
  }
  .teaser__item {
    position: relative;
    z-index: 2;
    align-items: flex-start;
    padding: 100px 0 0 70px;
    border: 0;
    .teaser__time {
      position: absolute;
      top: 40px;
      color: lib.$col-headline;
      font-weight: bold;
      &:before {
        position: absolute;
        content: '';
        top: -5px;
        left: -68px;
        display: block;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 10px solid #fff;
        background: #e60005;
      }
    }
    .teaser__content {
      .button {
        margin-top: 10px;
      }
    }
  }
  @include lib.breakpoint-max(md) {
    .teaser__item {
      display: block;
      .teaser__media {
        width: auto;
        max-width: 480px;
        margin-bottom: 30px;
        &.empty {
          display: none;
        }
      }
      .teaser__content {
        width: auto;
        margin: 0;
        p,
        .teaser__text {
          display: block;
        }
      }
    }
  }
  @include lib.breakpoint-max(sm) {
    &:before {
      left: -20px;
    }
    .teaser__item {
      padding: 90px 0 0 0px;
      .teaser__time {
        left: 8px;
        &:before {
          top: -2px;
          left: -41px;
          height: 30px;
          width: 30px;
          border: 5px solid #fff;
        }
      }
    }
  }
}
