// ==========================
// ===== drk_honorary =====
// ==========================

@use '../Abstracts' as lib;
@import '../Atoms/utility-classes';

.frame-type-drkhonoraryform_honoraryform {

  @include bg;
  @include bg--lightgrey;

  width: unset;
  max-width: unset;

  padding-top: lib.$size-32;
  padding-bottom: lib.$size-32;


}

.tx-drk-honoraryform-confirmation {
  @include lib.set-content-width(lib.$med-content-width);
}
