@use '../Abstracts' as lib;

// =========================
// ===== Trust Element =====
// =========================

.frame-type-plusdrkcontentelements_trust {
  width: unset;
  max-width: unset;
}

.trust {
  @include lib.breakpoint-max(sm) {
    background-image: none !important;
  }

  @include lib.breakpoint-min(sm) {
    display: flex;
  }

  &__image {
    @include lib.breakpoint-min(sm) {
      display: none;
    }
  }

  &__content {
    padding: lib.$size-16 0;
    background-color: lib.$col-white;

    @include lib.breakpoint-min(sm) {
      display: flex;
      gap: lib.$size-32;
      padding: lib.$size-32;
      border-radius: lib.$size-8;
      margin-block: lib.$size-32;
    }

    @include lib.set-content-width(lib.$med-content-width);
  }

  &__title {
    display: block;
  }

  &__text {
    @include lib.breakpoint-min(sm) {
      width: 50%;
    }
  }

  &__chart {
    @include lib.breakpoint-min(sm) {
      width: 50%;
    }
  }
}
