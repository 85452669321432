// =========================================
// ===== Accessibility Fixes / Classes =====
// =========================================

// Hide something from the screen but keep it visible for assistive technology
@mixin visually-hidden {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.visually-hidden, .h-hide {
  @include visually-hidden;
}

// Remove all animations, transitions and smooth scroll
// for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *:before,
  *:after {
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: .01ms !important;
    scroll-behavior: auto !important;
  }
}
