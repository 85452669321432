@use '../Abstracts' as lib;

// =================================
// ===== NEWSLETTER FORM ELEMENT =====
// =================================

.c-shortsubscribe-wrap {
  width: unset;
  max-width: unset;
  padding: lib.$size-32;


  fieldset {
    margin-bottom: 0;
  }

  @include lib.breakpoint-min(sm) {
    @include lib.set-content-width(lib.$min-content-width);

    padding: lib.$size-64 0;
  }

  @include lib.breakpoint-max(sm) {
    margin-inline: 2rem;
  }

  .o-form-item, .t-medium-22, .columns {
    padding-block: lib.$size-16;
  }

  .o-form__submit {
    color: lib.$col-blue;
  }
}

// subscribe form
.newsletter {
  &__type {
    margin-block-end: lib.$size-48;
  }

  &__policy {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-block-end: lib.$size-16;

    input {
      top: 0;
    }

    span {
      grid-column: span 2;
    }

    label {
      position: revert;
      font-size: initial;
      font-weight: inherit;
    }
  }

  &__salutation {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    margin-block-end: lib.$size-16 !important;
  }

  &__personal-data {
    margin-block-end: lib.$size-16;
  }

  &__button {
    display: flex;
  }
}

.c-newsletter__form {
  @include lib.set-content-width(lib.$min-content-width);
}

.o-form__section-headline {
  margin-bottom: lib.$size-16;
  color: lib.$col-headline;
  text-align: center;
}

.o-check-group__option-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  border: 1px solid lib.$col-black-20;
  border-radius: 16px;
  margin-block-end: lib.$size-16;

  input {
    top: 0;
  }

  label {
    width: 100%;
    margin-bottom: 0;
    font-size: lib.$font-main-size-fluid;

    &:hover {
      cursor: pointer;
    }
  }
}

// success message
// stylelint-disable-next-line
#c-newsletter__form {
  ul.list {
    li {
      margin-bottom: lib.$size-16;
      margin-left: 0;
      padding: lib.$size-8;
      border: lib.rem(2px) solid lib.$col-black-20;
      border-radius: lib.rem(24px);
      list-style: none;

      @include lib.breakpoint-min(sm) {
        padding: lib.$size-16;
      }

      &:before {
        content: none;
      }
    }
  }

  @include lib.set-content-width(lib.$min-content-width);
}

// Custom style for presseverteiler form
/* stylelint-disable-next-line */
#subscribe_1_ {
  .o-check-group {
    .t-medium-24 {
      display: flex;
      flex-wrap: wrap;

      input {
        flex: 0;
      }

      label {
        position: unset;
        flex: 1;
      }
    }
  }
}
