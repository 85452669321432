@use '../../../../../../node_modules/swiper/swiper' as swiper;
@use '../../../../../../node_modules/swiper/modules/navigation/navigation' as swiper-navigation;
@use '../../../../../../node_modules/swiper/modules/pagination/pagination' as swiper-pagination;
@use '../../../../../../node_modules/swiper/modules/a11y/a11y' as swiper-a11y;
@use '../../../../../../node_modules/swiper/modules/free-mode/free-mode' as swiper-free-mode;
@use '../Abstracts' as lib;

// =========================
// ===== Video Element =====
// =========================

$-video-swiper-breakpoint: lib.$med-content-width + lib.$size-64 + lib.$size-32;

.frame-type-plusdrkcontentelements_video_gallery {
  width: 100%;
  max-width: none;

  .bodytext {
    @include lib.set-content-width(lib.$min-content-width);
  }
}

.video__swiper {
  margin-block: lib.$size-32;

  .swiper-slide {
    width: 100%;
    max-width: calc(100% - #{lib.$size-128});

    &:first-child {
      margin-inline-start: lib.$size-32;
    }

    @media (min-width: $-video-swiper-breakpoint) {
      max-width: lib.$med-content-width;

      &:first-child {
        margin-inline-start: 0;
      }
    }
  }

  .swiper-actions {
    display: flex;
    gap: lib.$size-16;
    justify-content: center;
    align-items: center;
    margin-block: lib.$size-12 lib.rem(4px);

    @include lib.set-content-width(lib.$med-content-width);
  }

  .swiper-pagination {
    display: none;

    @media (min-width: $-video-swiper-breakpoint) {
      position: static;
      display: flex;
      align-items: center;
      width: auto;
      margin-inline-end: auto;
    }

    .swiper-pagination-bullet {
      background: lib.$col-black-20;

      &:focus-visible {
        outline: 2px solid lib.$col-link;
        outline-offset: 2px;
      }

      &.swiper-pagination-bullet-active {
        background: lib.$col-text;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    width: lib.$size-48;
    height: lib.$size-48;
    margin: 0;
    border-radius: 50%;
    background-color: lib.$col-black-05;
    color: lib.$col-black-80;
    transform: rotate(90deg);

    &:after {
      content: none;
    }

    svg {
      width: lib.$size-16;
      margin-block: lib.rem(3px) 0;
    }

    &:focus-visible {
      outline: 2px solid lib.$col-link;
      outline-offset: 2px;
    }
  }

  .swiper-button-next {
    transform: rotate(-90deg);
  }
}

.video {
  text-align: center;

  @include lib.set-content-width(lib.$med-content-width);

  .swiper-slide & {
    width: auto;
    max-width: unset;
    opacity: .4;
    pointer-events: none;
  }

  .swiper-slide-active & {
    opacity: 1;
    pointer-events: auto;
  }

  &__iframe-scaler {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: lib.$size-24;
    background-color: lib.$col-black-100;
    aspect-ratio: 16 / 9;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: lib.$col-black-100;
  }

  &__local {
    display: block;
    overflow: hidden;
    width: 100%;
    border-radius: lib.$size-24;
  }

  &__title,
  &__description {
    margin-block-end: 0;

    @media (min-width: $-video-swiper-breakpoint) {
      @include lib.set-content-width(lib.$min-content-width);
    }
  }

  &__title {
    margin-block-start: lib.$size-12;
  }

  &__description {
    color: lib.$col-text-shaded;
  }
}
