@use '../Abstracts' as lib;

// ==========================
// ===== CONTACT PERSON =====
// ==========================

// stylesheet for CType "frs_drk_template_contact_person" and "frs_drk_template_teamleader"
.contactperson {
  &__item {
    overflow: hidden;
    border-radius: lib.rem(6px);
    margin-block-end: lib.$size-32;

    @media (min-width: 450px) {
      display: flex;
    }
  }

  &__image {
    width: 100%;

    @media (min-width: 450px) {
      width: 40%;
    }

    @include lib.breakpoint-min(sm) {
      width: 100%;
      max-width: 200px;
    }

    @include lib.breakpoint-min(md) {
      max-width: 260px;
    }

    &--placeholder {
      background-image: url('../Icons/person-fallback.svg');
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      aspect-ratio: 2/3;
    }

    img {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: lib.$size-16;

    @media (min-width: 450px) {
      width: 60%;
      padding: lib.$size-12 lib.$size-16;
    }

    @include lib.breakpoint-min(sm) {
      width: 100%;
      padding: lib.$size-32;
    }

    a {
      text-decoration: none;
      word-break: break-all;

      &:not(:last-child) {
        margin-block-end: lib.$size-8;

        @include lib.breakpoint-min(xs) {
          margin-block-end: 0;
        }

        @include lib.breakpoint-min(sm) {
          margin-block-end: lib.$size-16;
        }
      }

      @include lib.breakpoint-max(sm) {
        font-size: lib.$font-small-size-fluid;
      }
    }
  }

  &__title,
  &__company {
    line-height: 1.2em;

    @include lib.breakpoint-min(xs) {
      margin-block-end: lib.$size-8;
    }

    @include lib.breakpoint-min(sm) {
      margin-block-end: revert;
    }
  }

  &__name {
    margin-block-end: 0;
  }

  &__teamlink {
    width: fit-content;
  }
}
